import { graphql } from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment coursesWithVisibilityConfigsTable_QueryFragment on Query
	@refetchable(queryName: "coursesWithVisibilityConfigsTable_Refetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 20 }
		after: { type: "String" }
		last: { type: "Int" }
		before: { type: "String" }
		visibilityTreeConfigKinds: { type: "[VisibilityTreeConfigType!]!" }
	) {
		Admin {
			VisibilitySchedule {
				SearchRootNodesByVisibilityConfigKinds(
					first: $first
					after: $after
					last: $last
					before: $before
					filterByVisibilityTreeConfigKinds: $visibilityTreeConfigKinds
				)
					@connection(
						key: "coursesWithVisibilityConfigsTable_SearchRootNodesByVisibilityConfigKinds"
					) {
					__id
					edges {
						node {
							...coursesWithVisibilityConfigsTable_LearnOpportunityV2InlineFragment
						}
					}
					pageInfo {
						startCursor
						endCursor
						hasNextPage
						hasPreviousPage
					}
				}
			}
		}
	}
`;

export const LEARN_OPPORTUNITY_V2_INLINE_FRAGMENT = graphql`
	fragment coursesWithVisibilityConfigsTable_LearnOpportunityV2InlineFragment on LearnOpportunityV2
	@inline {
		structureDefinition {
			title
		}
		...editVisibilitySchedulesButton_LearnOpportunityV2ragment
	}
`;
