import { EpicIcons } from "@thekeytechnology/epic-ui";
import { Toast } from "primereact/toast";
import React, { useRef } from "react";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router";
import { Button, ButtonVariant } from "@components/button";
import { editVisibilitySchedulesButton_LearnOpportunityV2ragment$key } from "@relay/editVisibilitySchedulesButton_LearnOpportunityV2ragment.graphql";
import { Paths } from "@routes/paths";
import { LEARN_OPPORTUNITY_V2_FRAGMENT } from "@screens/courses-with-visibility-configs/parts/edit-visibility-schedules-button/edit-visibility-schedules-button.graphql";
import { EditVisibilitySchedulesButtonProps } from "@screens/courses-with-visibility-configs/parts/edit-visibility-schedules-button/edit-visibility-schedules-button.types";

export const EditVisibilitySchedulesButton = ({
	learnOpportunityV2FragmentRef,
}: EditVisibilitySchedulesButtonProps) => {
	const toast = useRef<Toast>(null);
	const navigate = useNavigate();
	const learnOpportunityV2 =
		useFragment<editVisibilitySchedulesButton_LearnOpportunityV2ragment$key>(
			LEARN_OPPORTUNITY_V2_FRAGMENT,
			learnOpportunityV2FragmentRef,
		);

	const handleEditVisibilitySchedules = () => {
		navigate(
			Paths.coursesWithVisibilitySchedules.withId(learnOpportunityV2.id).visibilitySchedules
				.path,
		);
	};

	return (
		<>
			<Toast ref={toast} />
			<Button
				icon={EpicIcons.CLOCK}
				variant={ButtonVariant.Strong}
				tooltip="Kurs-Verfügbarkeiten bearbeiten"
				onClick={handleEditVisibilitySchedules}
			/>
		</>
	);
};
