import { useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { H1Span } from "@themes/font-tags";
import { OffersScreenContext } from "./offers.context";
import { QUERY } from "./offers.graphql";
import { OffersContextProvider } from "./offers.provider";
import { HeaderWrapper, Wrapper } from "./offers.styles";
import { CreateCoachingOfferButton } from "./parts/create-coaching-offer-button";
import { CreateCourseLearnableButton } from "./parts/create-course-learnable-button";
import { OffersFilters } from "./parts/offers-filters";
import { OffersTable } from "./parts/offers-table";
import { type offers_Query } from "../../__generated__/offers_Query.graphql";
import { BaseScreen } from "../BaseScreen";

export const OffersScreen = () => {
	const {
		filters: { ...filters },
	} = useContext(OffersScreenContext);

	const query = useLazyLoadQuery<offers_Query>(
		QUERY,
		{ ...filters },
		{ fetchPolicy: "network-only" },
	);

	return (
		<BaseScreen>
			<OffersContextProvider>
				<HeaderWrapper>
					<H1Span>Angebote</H1Span>
					<Wrapper>
						<CreateCourseLearnableButton />
						<CreateCoachingOfferButton />
						<OffersFilters queryFragmentRef={query} />
					</Wrapper>
				</HeaderWrapper>
				<OffersTable queryFragmentRef={query} />
			</OffersContextProvider>
		</BaseScreen>
	);
};
