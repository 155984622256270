/**
 * @generated SignedSource<<e38b8a5a663116ab1fd0d6effe323f6d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateUserInAccountGroupVisibilityScheduleInput = {
  accountId: string;
  clientMutationId?: string | null | undefined;
  data: UserInAccountGroupVisibilityScheduleDataInput;
  rootId: string;
  visibleFrom: any;
  visibleUntil: any;
};
export type UserInAccountGroupVisibilityScheduleDataInput = {
  userInAccountGroupIds: ReadonlyArray<string>;
};
export type createVisibilityScheduleButton_CreateUserInAccountGroupVisibilityScheduleMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateUserInAccountGroupVisibilityScheduleInput;
};
export type createVisibilityScheduleButton_CreateUserInAccountGroupVisibilityScheduleMutation$data = {
  readonly Admin: {
    readonly VisibilitySchedule: {
      readonly createUserInAccoutGroupVisibilitySchedule: {
        readonly visibilitySchedule: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"visibilitySchedulesTable_VisibilitySchedule_VisibilityScheduleInlineFragment">;
          };
        };
      } | null | undefined;
    };
  };
};
export type createVisibilityScheduleButton_CreateUserInAccountGroupVisibilityScheduleMutation = {
  response: createVisibilityScheduleButton_CreateUserInAccountGroupVisibilityScheduleMutation$data;
  variables: createVisibilityScheduleButton_CreateUserInAccountGroupVisibilityScheduleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visibleFrom",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visibleUntil",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v9 = [
  (v4/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "createVisibilityScheduleButton_CreateUserInAccountGroupVisibilityScheduleMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VisibilityScheduleAdminMutationSchema",
            "kind": "LinkedField",
            "name": "VisibilitySchedule",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateUserInAccountGroupVisibilitySchedulePayload",
                "kind": "LinkedField",
                "name": "createUserInAccoutGroupVisibilitySchedule",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VisibilitySchedule_VisibilityScheduleEdge",
                    "kind": "LinkedField",
                    "name": "visibilitySchedule",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "VisibilitySchedule_VisibilitySchedule",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "visibilitySchedulesTable_VisibilitySchedule_VisibilityScheduleInlineFragment",
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Account",
                                "kind": "LinkedField",
                                "name": "account",
                                "plural": false,
                                "selections": (v5/*: any*/),
                                "storageKey": null
                              },
                              (v6/*: any*/),
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "data",
                                "plural": false,
                                "selections": [
                                  (v8/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "User",
                                        "kind": "LinkedField",
                                        "name": "users",
                                        "plural": true,
                                        "selections": (v5/*: any*/),
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "SingleUserVisibilitySchedule",
                                    "abstractKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "UserInAccountGroup",
                                        "kind": "LinkedField",
                                        "name": "userInAccountGroups",
                                        "plural": true,
                                        "selections": (v5/*: any*/),
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "UserInAccountGroupVisibilitySchedule",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "deleteVisibilityScheduleButton_VisibilitySchedule_VisibilityScheduleFragment"
                              }
                            ],
                            "args": null,
                            "argumentDefinitions": []
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "createVisibilityScheduleButton_CreateUserInAccountGroupVisibilityScheduleMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VisibilityScheduleAdminMutationSchema",
            "kind": "LinkedField",
            "name": "VisibilitySchedule",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateUserInAccountGroupVisibilitySchedulePayload",
                "kind": "LinkedField",
                "name": "createUserInAccoutGroupVisibilitySchedule",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VisibilitySchedule_VisibilityScheduleEdge",
                    "kind": "LinkedField",
                    "name": "visibilitySchedule",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "VisibilitySchedule_VisibilitySchedule",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Account",
                            "kind": "LinkedField",
                            "name": "account",
                            "plural": false,
                            "selections": (v9/*: any*/),
                            "storageKey": null
                          },
                          (v6/*: any*/),
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "data",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              },
                              {
                                "kind": "TypeDiscriminator",
                                "abstractKey": "__isVisibilityScheduleData"
                              },
                              (v8/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "users",
                                    "plural": true,
                                    "selections": (v9/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "type": "SingleUserVisibilitySchedule",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "UserInAccountGroup",
                                    "kind": "LinkedField",
                                    "name": "userInAccountGroups",
                                    "plural": true,
                                    "selections": (v9/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "type": "UserInAccountGroupVisibilitySchedule",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "appendEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "visibilitySchedule",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "31954aa25b2e01146adf6fabb10e7d9c",
    "id": null,
    "metadata": {},
    "name": "createVisibilityScheduleButton_CreateUserInAccountGroupVisibilityScheduleMutation",
    "operationKind": "mutation",
    "text": "mutation createVisibilityScheduleButton_CreateUserInAccountGroupVisibilityScheduleMutation(\n  $input: CreateUserInAccountGroupVisibilityScheduleInput!\n) {\n  Admin {\n    VisibilitySchedule {\n      createUserInAccoutGroupVisibilitySchedule(input: $input) {\n        visibilitySchedule {\n          node {\n            ...visibilitySchedulesTable_VisibilitySchedule_VisibilityScheduleInlineFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment deleteVisibilityScheduleButton_VisibilitySchedule_VisibilityScheduleFragment on VisibilitySchedule_VisibilitySchedule {\n  id\n}\n\nfragment visibilitySchedulesTable_VisibilitySchedule_VisibilityScheduleInlineFragment on VisibilitySchedule_VisibilitySchedule {\n  id\n  account {\n    name\n    id\n  }\n  visibleFrom\n  visibleUntil\n  data {\n    __typename\n    __isVisibilityScheduleData: __typename\n    kind\n    ... on SingleUserVisibilitySchedule {\n      users {\n        name\n        id\n      }\n    }\n    ... on UserInAccountGroupVisibilitySchedule {\n      userInAccountGroups {\n        name\n        id\n      }\n    }\n  }\n  ...deleteVisibilityScheduleButton_VisibilitySchedule_VisibilityScheduleFragment\n}\n"
  }
};
})();

(node as any).hash = "e8f409bf3c349d1d14932d7092c1bd38";

export default node;
