/**
 * @generated SignedSource<<0e865413fe09b52cd46ff6f1b75f5214>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VisibilitySchedule_VisibilityScheduleDataKind = "SingleUserData" | "UserInAccountGroupData";
export type visibilitySchedules_Query$variables = {
  accountId?: string | null | undefined;
  after?: string | null | undefined;
  before?: string | null | undefined;
  dataKinds: ReadonlyArray<VisibilitySchedule_VisibilityScheduleDataKind>;
  first?: number | null | undefined;
  last?: number | null | undefined;
  rootId: string;
};
export type visibilitySchedules_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"visibilitySchedulesTable_QueryFragment">;
};
export type visibilitySchedules_Query = {
  response: visibilitySchedules_Query$data;
  variables: visibilitySchedules_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dataKinds"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "rootId"
},
v7 = [
  {
    "kind": "Variable",
    "name": "accountId",
    "variableName": "accountId"
  },
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "dataKinds",
    "variableName": "dataKinds"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "rootId",
    "variableName": "rootId"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  (v8/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "visibilitySchedules_Query",
    "selections": [
      {
        "args": (v7/*: any*/),
        "kind": "FragmentSpread",
        "name": "visibilitySchedulesTable_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v6/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "visibilitySchedules_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VisibilityScheduleAdminSchema",
            "kind": "LinkedField",
            "name": "VisibilitySchedule",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "VisibilitySchedule_VisibilityScheduleConnection",
                "kind": "LinkedField",
                "name": "SearchVisibilitySchedules",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VisibilitySchedule_VisibilityScheduleEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "VisibilitySchedule_VisibilitySchedule",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Account",
                            "kind": "LinkedField",
                            "name": "account",
                            "plural": false,
                            "selections": (v9/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "visibleFrom",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "visibleUntil",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "data",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              {
                                "kind": "TypeDiscriminator",
                                "abstractKey": "__isVisibilityScheduleData"
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "kind",
                                "storageKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "users",
                                    "plural": true,
                                    "selections": (v9/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "type": "SingleUserVisibilitySchedule",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "UserInAccountGroup",
                                    "kind": "LinkedField",
                                    "name": "userInAccountGroups",
                                    "plural": true,
                                    "selections": (v9/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "type": "UserInAccountGroupVisibilitySchedule",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v7/*: any*/),
                "filters": [
                  "accountId",
                  "rootId",
                  "dataKinds"
                ],
                "handle": "connection",
                "key": "visibilitySchedulesTable_VisibilitySchedule_SearchVisibilitySchedules",
                "kind": "LinkedHandle",
                "name": "SearchVisibilitySchedules"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4bf9b043b347dfb4a3e36b3c0dded3e8",
    "id": null,
    "metadata": {},
    "name": "visibilitySchedules_Query",
    "operationKind": "query",
    "text": "query visibilitySchedules_Query(\n  $first: Int\n  $after: String\n  $before: String\n  $last: Int\n  $accountId: ID\n  $rootId: ID!\n  $dataKinds: [VisibilitySchedule_VisibilityScheduleDataKind!]!\n) {\n  ...visibilitySchedulesTable_QueryFragment_4yeO5R\n}\n\nfragment deleteVisibilityScheduleButton_VisibilitySchedule_VisibilityScheduleFragment on VisibilitySchedule_VisibilitySchedule {\n  id\n}\n\nfragment visibilitySchedulesTable_QueryFragment_4yeO5R on Query {\n  Admin {\n    VisibilitySchedule {\n      SearchVisibilitySchedules(first: $first, after: $after, last: $last, before: $before, accountId: $accountId, rootId: $rootId, dataKinds: $dataKinds) {\n        edges {\n          node {\n            ...visibilitySchedulesTable_VisibilitySchedule_VisibilityScheduleInlineFragment\n            id\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          startCursor\n          endCursor\n          hasNextPage\n          hasPreviousPage\n        }\n      }\n    }\n  }\n}\n\nfragment visibilitySchedulesTable_VisibilitySchedule_VisibilityScheduleInlineFragment on VisibilitySchedule_VisibilitySchedule {\n  id\n  account {\n    name\n    id\n  }\n  visibleFrom\n  visibleUntil\n  data {\n    __typename\n    __isVisibilityScheduleData: __typename\n    kind\n    ... on SingleUserVisibilitySchedule {\n      users {\n        name\n        id\n      }\n    }\n    ... on UserInAccountGroupVisibilitySchedule {\n      userInAccountGroups {\n        name\n        id\n      }\n    }\n  }\n  ...deleteVisibilityScheduleButton_VisibilitySchedule_VisibilityScheduleFragment\n}\n"
  }
};
})();

(node as any).hash = "e2a0afc901c0486348a8c46ba769616b";

export default node;
