import { type FormikProps, useFormik } from "formik";
import moment from "moment/moment";
import { Calendar } from "primereact/calendar";
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";
import React, {
	forwardRef,
	ReactNode,
	useEffect,
	useImperativeHandle,
	useMemo,
	useState,
} from "react";
import { useSelector } from "react-redux";
import { useRelayEnvironment } from "react-relay";
import { fetchQuery } from "relay-runtime";
import { match } from "ts-pattern";
import { ValidatedField } from "@components/ValidatedField";
import {
	SelectAccountField,
	SelectAccountFieldState,
} from "@features/accounts/select-account-field";
import { SelectUserInAccountGroupFieldDialog } from "@features/accounts/select-user-in-account-group-field-dialog";
import { SelectUserFieldDialog } from "@features/users/select-user-field-dialog";
import { useCheckPermissions } from "@hooks/use-check-permissions";
import { visibilityScheduleForm_AccountsQuery } from "@relay/visibilityScheduleForm_AccountsQuery.graphql";
import { getVisibilityScheduleFormSchema } from "@screens/visibility-schedules/parts/visibility-schedule-form/visibility-schedule-form.consts";
import { radioButtonWrapperClass } from "@screens/visibility-schedules/parts/visibility-schedule-form/visibility-schedule-form.styles";
import {
	FormProps,
	VisibilityScheduleFormState,
} from "@screens/visibility-schedules/parts/visibility-schedule-form/visibility-schedule-form.types";
import { selectCurrentAccountId } from "@store/slices/AuthSlice";
import { ACCOUNTS_QUERY } from "./visibility-schedule-form.graphql";

export const VisibilityScheduleForm = forwardRef<
	FormikProps<VisibilityScheduleFormState>,
	FormProps<VisibilityScheduleFormState>
>(function VisibilityScheduleForm({ onSubmit }, ref) {
	const checkPermissions = useCheckPermissions();
	const isRootAccount = checkPermissions(["AccountPermission_System_Root"]);
	const currentAccountId = useSelector(selectCurrentAccountId) ?? "";
	const [accountOptions, setAccountOptions] =
		useState<
			visibilityScheduleForm_AccountsQuery["response"]["Admin"]["VisibilitySchedule"]["GetAccountsForVisibilitySchedule"]
		>();
	const environment = useRelayEnvironment();

	useEffect(() => {
		if (!isRootAccount) return;

		const fetchAccountData = async () => {
			const result = await fetchQuery<visibilityScheduleForm_AccountsQuery>(
				environment,
				ACCOUNTS_QUERY,
				{},
			).toPromise();

			setAccountOptions(result?.Admin.VisibilitySchedule.GetAccountsForVisibilitySchedule);
		};

		fetchAccountData();
	}, []);

	const initialValues: VisibilityScheduleFormState = {
		visibleFrom: "",
		visibleUntil: "",
		dataKind: "SingleUserData",
		userId: [],
		userInAccountGroupId: [],
	};

	const formik = useFormik<VisibilityScheduleFormState>({
		initialValues,
		validationSchema: getVisibilityScheduleFormSchema(isRootAccount),
		onSubmit,
	});

	const handleSingleUserVisibilityScheduleDataKindInputChange = (
		event: RadioButtonChangeEvent,
	) => {
		if (!event.checked) return;
		void formik.setFieldValue("dataKind", "SingleUserData");
		void formik.setFieldTouched("dataKind", true, false);
	};

	const handleUserInAccountGroupVisibilityScheduleDataKindInputChange = (
		event: RadioButtonChangeEvent,
	) => {
		if (!event.checked) return;
		void formik.setFieldValue("dataKind", "UserInAccountGroupData");
		void formik.setFieldTouched("dataKind", true, false);
	};

	const handleSingleUserSelection = (userId?: string) => {
		void formik.setFieldValue("userId", [userId]);
		void formik.setFieldTouched("userId", true, false);
	};

	const singleUserIsChecked = formik.values.dataKind === "SingleUserData";

	const accountId = isRootAccount ? formik.values.account?.id! : currentAccountId;

	useImperativeHandle(ref, () => ({
		...formik,
	}));

	const visibilityScheduleDataSelection: ReactNode = useMemo(() => {
		return match(formik.values.dataKind)
			.with("SingleUserData", () => (
				<ValidatedField<VisibilityScheduleFormState, string>
					required
					name={"userId"}
					label={"Bitte einen Nutzer auswählen"}
					formikConfig={formik}
					component={(config) => {
						return (
							<SelectUserFieldDialog
								{...config}
								fieldValue={formik.values.userId ? formik.values.userId[0] : ""}
								updateField={handleSingleUserSelection}
								selectVisibilityScheduleUsers
								accountId={
									isRootAccount ? formik.values.account?.id! : currentAccountId
								}
							/>
						);
					}}
				/>
			))
			.with("UserInAccountGroupData", () => (
				<ValidatedField<VisibilityScheduleFormState, string[]>
					required
					name={"userInAccountGroupId"}
					label={"Bitte eine Nutzergruppe auswählen"}
					formikConfig={formik}
					component={(config) => {
						return (
							<SelectUserInAccountGroupFieldDialog
								{...config}
								selectVisibilityScheduleUsers
								accountId={accountId}
							/>
						);
					}}
				/>
			))
			.exhaustive();
	}, [accountId, currentAccountId, formik, handleSingleUserSelection, isRootAccount]);

	return (
		<form onSubmit={formik.handleSubmit}>
			{isRootAccount && (
				<ValidatedField<VisibilityScheduleFormState, SelectAccountFieldState>
					required
					name={"account"}
					label="Konto"
					placeholder="Konto auswählen..."
					formikConfig={formik}
					component={(renderConfig) => (
						<SelectAccountField
							{...renderConfig}
							accountsFragmentRef={accountOptions}
						/>
					)}
				/>
			)}
			{accountId && (
				<>
					<ValidatedField<VisibilityScheduleFormState, string>
						required
						name={"visibleFrom"}
						label={"Sichtbar ab"}
						component={({ fieldName, fieldValue, updateField }) => (
							<Calendar
								name={fieldName}
								dateFormat={"dd.mm.yy"}
								showButtonBar
								value={
									fieldValue
										? moment(fieldValue.replace("[UTC]", "")).toDate()
										: undefined
								}
								onChange={(e) => {
									updateField(
										e.value
											? moment(e.value as Date).format("YYYY-MM-DD")
											: undefined,
									);
								}}
							/>
						)}
						formikConfig={formik}
					/>
					<ValidatedField<VisibilityScheduleFormState, string>
						required
						name={"visibleUntil"}
						label={"Sichtbar bis"}
						component={({ fieldName, fieldValue, updateField }) => (
							<Calendar
								name={fieldName}
								dateFormat={"dd.mm.yy"}
								showButtonBar
								value={
									fieldValue
										? moment(fieldValue.replace("[UTC]", "")).toDate()
										: undefined
								}
								onChange={(e) => {
									updateField(
										e.value
											? moment(e.value as Date).format("YYYY-MM-DD")
											: undefined,
									);
								}}
							/>
						)}
						formikConfig={formik}
					/>
					<div className={radioButtonWrapperClass}>
						<RadioButton
							required
							inputId="singleUser"
							checked={singleUserIsChecked}
							onChange={handleSingleUserVisibilityScheduleDataKindInputChange}
						/>
						<label htmlFor="singleUser">Nutzer</label>
						<RadioButton
							required
							inputId="userInAccountGroup"
							checked={!singleUserIsChecked}
							onChange={handleUserInAccountGroupVisibilityScheduleDataKindInputChange}
						/>
						<label htmlFor="userInAccountGroup">Nutzergruppe</label>
					</div>
					{visibilityScheduleDataSelection}
				</>
			)}
		</form>
	);
});
