/**
 * @generated SignedSource<<a9d64a53ccff976ea02f0254c9bac9b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type deleteVisibilityScheduleButton_VisibilitySchedule_VisibilityScheduleFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "deleteVisibilityScheduleButton_VisibilitySchedule_VisibilityScheduleFragment";
};
export type deleteVisibilityScheduleButton_VisibilitySchedule_VisibilityScheduleFragment$key = {
  readonly " $data"?: deleteVisibilityScheduleButton_VisibilitySchedule_VisibilityScheduleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"deleteVisibilityScheduleButton_VisibilitySchedule_VisibilityScheduleFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "deleteVisibilityScheduleButton_VisibilitySchedule_VisibilityScheduleFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "VisibilitySchedule_VisibilitySchedule",
  "abstractKey": null
};

(node as any).hash = "75fb820b1281384fb2d9a6ad490fdf20";

export default node;
