import React from "react";
import { useLazyLoadQuery } from "react-relay";

import { NoAccess } from "@components/no-access";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { coursesWithVisibilityConfigs_Query } from "@relay/coursesWithVisibilityConfigs_Query.graphql";
import { headerWrapperClass } from "@screens/courses-with-visibility-configs/courses-with-visibility-configs.styles";
import { CoursesWithVisibilityConfigsTable } from "@screens/courses-with-visibility-configs/parts/courses-with-visibility-configs-table";
import { H1Span } from "@themes/font-tags";
import { QUERY } from "./courses-with-visibility-configs.graphql";
import { BaseScreen } from "../BaseScreen";

export const CoursesWithVisibilitySchedulesScreen = () => {
	const query = useLazyLoadQuery<coursesWithVisibilityConfigs_Query>(
		QUERY,
		{
			visibilityTreeConfigKinds: ["VisibilityTree_OnlyAZAVAccount"],
		},
		{
			fetchPolicy: "store-and-network",
		},
	);

	const canRead = useHasPermissions(
		["UserInAccountPermission_System_Owner", "AccountPermission_AZAVAdmin_IsAZAVAccount"],
		true,
	);

	if (!canRead) return <NoAccess />;

	return (
		<BaseScreen>
			<div className={headerWrapperClass}>
				<H1Span>Verfügbare Kurse</H1Span>
			</div>
			<CoursesWithVisibilityConfigsTable queryFragmentRef={query} />
		</BaseScreen>
	);
};
