import { PathWithId } from "@thekeytechnology/epic-ui";

import { VisibilitySchedulesPaths } from "@screens/visibility-schedules";

export class CoursesWithVisibilitySchedulesPaths extends PathWithId<
	typeof CoursesWithVisibilitySchedulesPaths
> {
	static readonly pathKey = "coursesWithVisibilitySchedules";
	static readonly pathName = "courses-with-visibility-schedules";
	static readonly idKey = "rootId";
	static readonly childPaths = [VisibilitySchedulesPaths];
}
