/**
 * @generated SignedSource<<1bfa725ed865d3bff8fd8b6e214f1838>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type selectAccountField_Query$variables = Record<PropertyKey, never>;
export type selectAccountField_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"selectAccountField_QueryFragment">;
};
export type selectAccountField_Query = {
  response: selectAccountField_Query$data;
  variables: selectAccountField_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "ids",
    "value": ([]/*: any*/)
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "selectAccountField_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "selectAccountField_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "selectAccountField_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthAdminSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v0/*: any*/),
                "concreteType": "AccountConnection",
                "kind": "LinkedField",
                "name": "SelectAccounts",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Account",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "SelectAccounts(ids:[])"
              },
              {
                "alias": null,
                "args": (v0/*: any*/),
                "filters": [
                  "nameOpt",
                  "ids"
                ],
                "handle": "connection",
                "key": "selectAccountField_SelectAccounts",
                "kind": "LinkedHandle",
                "name": "SelectAccounts"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a26fc3ea18a540e968d595127bde0210",
    "id": null,
    "metadata": {},
    "name": "selectAccountField_Query",
    "operationKind": "query",
    "text": "query selectAccountField_Query {\n  ...selectAccountField_QueryFragment\n}\n\nfragment selectAccountField_AccountFragment on Account {\n  id\n  name\n}\n\nfragment selectAccountField_QueryFragment on Query {\n  Admin {\n    Auth {\n      SelectAccounts(ids: []) {\n        edges {\n          node {\n            ...selectAccountField_AccountFragment\n            id\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c9c2499c0cc2a70e26ebe74b3293179b";

export default node;
