import { MultiSelect, type MultiSelectChangeEvent } from "primereact/multiselect";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRelayEnvironment } from "react-relay";
import { fetchQuery } from "relay-runtime";

import { selectUserInAccountGroupsField_Query } from "@relay/selectUserInAccountGroupsField_Query.graphql";
import { selectUserInAccountGroupsField_VisibilityScheduleUserInAccountGroupsQuery } from "@relay/selectUserInAccountGroupsField_VisibilityScheduleUserInAccountGroupsQuery.graphql";
import {
	QUERY,
	VISIBILITY_SCHEDULE_USERS_QUERY,
} from "./select-user-in-account-groups-field.graphql";
import {
	SelectUserInAccountGroupsFieldProps,
	UserInAccountGroupsQueryData,
	VisibilityScheduleUserInAccountGroupsQueryData,
} from "./select-user-in-account-groups-field.types";
import { convertGroupName } from "./select-user-in-account-groups-field.util";

export const SelectUserInAccountGroupsField = ({
	fieldValue,
	fieldName,
	updateField,
	placeholder,
	singleSelection,
	selectVisibilityScheduleUsers,
	accountId,
}: SelectUserInAccountGroupsFieldProps) => {
	const environment = useRelayEnvironment();
	const [groups, setGroups] = useState<
		VisibilityScheduleUserInAccountGroupsQueryData | UserInAccountGroupsQueryData | undefined
	>();

	const fetchGroups = useCallback(async () => {
		if (selectVisibilityScheduleUsers) {
			const result =
				await fetchQuery<selectUserInAccountGroupsField_VisibilityScheduleUserInAccountGroupsQuery>(
					environment,
					VISIBILITY_SCHEDULE_USERS_QUERY,
					{ accountId },
				).toPromise();
			setGroups(
				result?.Admin.VisibilitySchedule.SearchUserInAccountGroupsForVisibilitySchedule.slice(),
			);
		} else {
			const result = await fetchQuery<selectUserInAccountGroupsField_Query>(
				environment,
				QUERY,
				{
					id: accountId,
				},
			).toPromise();
			setGroups(result?.Admin.Auth.GetUserInAccountGroups.slice());
		}
	}, [accountId, environment, selectVisibilityScheduleUsers]);

	useEffect(() => {
		fetchGroups();
	}, [fetchGroups]);

	const options = useMemo(
		() =>
			groups?.map((group) => ({
				label: convertGroupName(group.name),
				value: group.id,
			})),
		[groups],
	);

	const handleOnChange = (event: MultiSelectChangeEvent) => {
		updateField(event.value);
	};

	return (
		<MultiSelect
			name={fieldName}
			placeholder={placeholder}
			value={fieldValue}
			options={options}
			onChange={handleOnChange}
			filter
			showSelectAll={!singleSelection}
			filterBy={"label"}
			selectionLimit={singleSelection ? 1 : undefined}
		/>
	);
};
