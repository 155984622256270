/**
 * @generated SignedSource<<1204f5433501bedf03d93e5310aefbc9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type VisibilitySchedule_VisibilityScheduleDataKind = "SingleUserData" | "UserInAccountGroupData";
import { FragmentRefs } from "relay-runtime";
export type visibilitySchedulesTable_VisibilitySchedule_VisibilityScheduleInlineFragment$data = {
  readonly account: {
    readonly name: string;
  };
  readonly data: {
    readonly kind: VisibilitySchedule_VisibilityScheduleDataKind;
    readonly userInAccountGroups?: ReadonlyArray<{
      readonly name: string;
    }>;
    readonly users?: ReadonlyArray<{
      readonly name: string;
    }>;
  };
  readonly id: string;
  readonly visibleFrom: any;
  readonly visibleUntil: any;
  readonly " $fragmentSpreads": FragmentRefs<"deleteVisibilityScheduleButton_VisibilitySchedule_VisibilityScheduleFragment">;
  readonly " $fragmentType": "visibilitySchedulesTable_VisibilitySchedule_VisibilityScheduleInlineFragment";
};
export type visibilitySchedulesTable_VisibilitySchedule_VisibilityScheduleInlineFragment$key = {
  readonly " $data"?: visibilitySchedulesTable_VisibilitySchedule_VisibilityScheduleInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"visibilitySchedulesTable_VisibilitySchedule_VisibilityScheduleInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "visibilitySchedulesTable_VisibilitySchedule_VisibilityScheduleInlineFragment"
};

(node as any).hash = "243c1b58b5732035ba32b054f5c12f2e";

export default node;
