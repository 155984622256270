import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query selectUserInAccountGroupFieldDialog_Query($userInAccountGroupId: ID!, $skip: Boolean!) {
		node(id: $userInAccountGroupId) @skip(if: $skip) {
			... on UserInAccountGroup {
				name
			}
		}
	}
`;

export const VISIBILITY_SCHEDULE_USER_IN_ACCOUNT_GROUP_QUERY = graphql`
	query selectUserInAccountGroupFieldDialog_VisibilityScheduleUserInAccountGroupQuery(
		$userInAccountGroupId: ID!
	) {
		Admin {
			VisibilitySchedule {
				GetUserInAccountGroupForVisibilitySchedule(id: $userInAccountGroupId) {
					name
				}
			}
		}
	}
`;
