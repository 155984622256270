import { RelayPageDataTable } from "@thekeytechnology/epic-ui";
import { coursesWithVisibilityConfigsTable_LearnOpportunityV2InlineFragment$key } from "@relay/coursesWithVisibilityConfigsTable_LearnOpportunityV2InlineFragment.graphql";
import { coursesWithVisibilityConfigsTable_QueryFragment$key } from "@relay/coursesWithVisibilityConfigsTable_QueryFragment.graphql";
import { coursesWithVisibilityConfigsTable_Refetch } from "@relay/coursesWithVisibilityConfigsTable_Refetch.graphql";
import { CoursesWithVisibilityConfigsTableProps } from "@screens/courses-with-visibility-configs/parts/courses-with-visibility-configs-table/courses-with-visibility-configs-table.types";
import { EditVisibilitySchedulesButton } from "@screens/courses-with-visibility-configs/parts/edit-visibility-schedules-button/edit-visibility-schedules-button.component";
import { DEFAULT_ROWS_COUNT } from "./courses-with-visibility-configs-table.consts";
import {
	LEARN_OPPORTUNITY_V2_INLINE_FRAGMENT,
	QUERY_FRAGMENT,
} from "./courses-with-visibility-configs-table.graphql";

export const CoursesWithVisibilityConfigsTable = ({
	queryFragmentRef,
}: CoursesWithVisibilityConfigsTableProps) => {
	return (
		<RelayPageDataTable<
			coursesWithVisibilityConfigsTable_Refetch,
			coursesWithVisibilityConfigsTable_QueryFragment$key,
			coursesWithVisibilityConfigsTable_LearnOpportunityV2InlineFragment$key
		>
			queryFragment={QUERY_FRAGMENT}
			parentFragmentRef={queryFragmentRef}
			nodeInlineFragment={LEARN_OPPORTUNITY_V2_INLINE_FRAGMENT}
			extractConnection={(data) =>
				data.Admin.VisibilitySchedule.SearchRootNodesByVisibilityConfigKinds
			}
			rowsCount={DEFAULT_ROWS_COUNT}
		>
			{(Column) => (
				<>
					<Column header="Kurs" body={(rootNode) => rootNode.structureDefinition.title} />
					<Column
						header="Aktionen"
						style={{ width: "15%" }}
						body={(rootNode) => (
							<EditVisibilitySchedulesButton
								learnOpportunityV2FragmentRef={rootNode}
							/>
						)}
					/>
				</>
			)}
		</RelayPageDataTable>
	);
};
