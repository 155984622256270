/**
 * @generated SignedSource<<088f89444b4ef214a16e776f5dd4d14b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type VisibilityTreeConfigType = "VisibilityTree_AfterDateTime" | "VisibilityTree_Hide" | "VisibilityTree_NotAfterDateTime" | "VisibilityTree_OnlyAZAVAccount" | "VisibilityTree_OnlyAdmins" | "VisibilityTree_OnlyBusinessAccount" | "VisibilityTree_OnlyEditors" | "VisibilityTree_OnlyIfTreeState" | "VisibilityTree_OnlyPermissionsImpl" | "VisibilityTree_OnlyWithLicense";
import { FragmentRefs } from "relay-runtime";
export type AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment$data = {
  readonly configType: VisibilityTreeConfigType;
  readonly dateTime: string;
  readonly id: string;
  readonly " $fragmentType": "AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment";
};
export type AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment$key = {
  readonly " $data"?: AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "configType",
      "storageKey": null
    }
  ],
  "type": "NotAfterDateTimeVisibilityTreeConfig",
  "abstractKey": null
};

(node as any).hash = "e7710fd99160c0456e81abd1cac660de";

export default node;
