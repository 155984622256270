import { Paths } from "@routes/paths";
import type { RouteDefinition } from "@routes/route-definition";
import { CoursesWithVisibilitySchedulesScreen } from "@screens/courses-with-visibility-configs/courses-with-visibility-configs.screen";
import { VisibilitySchedulesScreen } from "@screens/visibility-schedules";

export const CoursesWithVisibilityConfigsRoutes: RouteDefinition[] = [
	{
		path: Paths.coursesWithVisibilitySchedules.path,
		element: <CoursesWithVisibilitySchedulesScreen />,
		requiredPermissions: "logged-in",
	},
	{
		path: `${
			Paths.coursesWithVisibilitySchedules.withIdPlaceholder().visibilitySchedules.path
		}/*`,
		element: <VisibilitySchedulesScreen />,
		requiredPermissions: "logged-in",
	},
];
