import { PathParams } from "@thekeytechnology/epic-ui";
import React, { useState } from "react";

import { useSelector } from "react-redux";
import { useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";
import { NoAccess } from "@components/no-access";
import { useCheckPermissions } from "@hooks/use-check-permissions";
import { useHasPermissions } from "@hooks/use-has-permissions";

import { visibilitySchedules_Query } from "@relay/visibilitySchedules_Query.graphql";
import { visibilitySchedulesScreen_LearnOpportunityV2Query } from "@relay/visibilitySchedulesScreen_LearnOpportunityV2Query.graphql";
import { CoursesWithVisibilitySchedulesPaths } from "@screens/courses-with-visibility-configs";
import { CreateVisibilityScheduleButton } from "@screens/visibility-schedules/parts/create-visibility-schedule-button/create-visibility-schedule-button.component";
import { VisibilitySchedulesTable } from "@screens/visibility-schedules/parts/visibility-schedules-table";
import { headerWrapperClass } from "@screens/visibility-schedules/parts/visibility-schedules-table/visibility-schedules-table.styles";
import { VisibilitySchedulesScreenContext } from "@screens/visibility-schedules/visibility-schedules.context";

import {
	LEARN_OPPORTUNITYV2_QUERY,
	QUERY,
} from "@screens/visibility-schedules/visibility-schedules.graphql";
import { selectCurrentAccountId } from "@store/slices/AuthSlice";
import { H1Span } from "@themes/font-tags";
import { BaseScreen } from "../BaseScreen";

export const VisibilitySchedulesScreen = () => {
	const [connectionId, setConnectionId] = useState("");
	const checkPermissions = useCheckPermissions();
	const { rootId } = useParams<PathParams<typeof CoursesWithVisibilitySchedulesPaths>>();
	const currentAccountId = useSelector(selectCurrentAccountId);
	const isRootAccount = checkPermissions(["AccountPermission_System_Root"]);

	const query = useLazyLoadQuery<visibilitySchedules_Query>(
		QUERY,
		{
			dataKinds: ["SingleUserData", "UserInAccountGroupData"],
			rootId: rootId ?? "",
			accountId: isRootAccount ? undefined : currentAccountId,
		},
		{
			fetchPolicy: "store-and-network",
		},
	);

	const learnOpportunity = useLazyLoadQuery<visibilitySchedulesScreen_LearnOpportunityV2Query>(
		LEARN_OPPORTUNITYV2_QUERY,
		{ rootId: rootId ?? "" },
	);

	const canRead = useHasPermissions(
		["UserInAccountPermission_System_Owner", "AccountPermission_AZAVAdmin_IsAZAVAccount"],
		true,
	);

	if (!canRead) return <NoAccess />;

	return (
		<BaseScreen>
			<VisibilitySchedulesScreenContext.Provider value={{ connectionId, setConnectionId }}>
				<div className={headerWrapperClass}>
					<H1Span>
						Kurs-Verfügbarkeiten für "
						{
							learnOpportunity?.Admin.VisibilitySchedule
								.GetRootNodeForVisibilitySchedule.structureDefinition.title
						}
						"
					</H1Span>
					<CreateVisibilityScheduleButton />
				</div>
				<VisibilitySchedulesTable queryFragmentRef={query} />
			</VisibilitySchedulesScreenContext.Provider>
		</BaseScreen>
	);
};
