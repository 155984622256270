/**
 * @generated SignedSource<<44194b10ba0cdf088d0a7e45837b0809>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type selectUserInAccountGroupsField_VisibilityScheduleUserInAccountGroupsQuery$variables = {
  accountId: string;
};
export type selectUserInAccountGroupsField_VisibilityScheduleUserInAccountGroupsQuery$data = {
  readonly Admin: {
    readonly VisibilitySchedule: {
      readonly SearchUserInAccountGroupsForVisibilitySchedule: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }>;
    };
  };
};
export type selectUserInAccountGroupsField_VisibilityScheduleUserInAccountGroupsQuery = {
  response: selectUserInAccountGroupsField_VisibilityScheduleUserInAccountGroupsQuery$data;
  variables: selectUserInAccountGroupsField_VisibilityScheduleUserInAccountGroupsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountId"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminQueryType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VisibilityScheduleAdminSchema",
        "kind": "LinkedField",
        "name": "VisibilitySchedule",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "filterByAccountId",
                "variableName": "accountId"
              }
            ],
            "concreteType": "UserInAccountGroup",
            "kind": "LinkedField",
            "name": "SearchUserInAccountGroupsForVisibilitySchedule",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "selectUserInAccountGroupsField_VisibilityScheduleUserInAccountGroupsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "selectUserInAccountGroupsField_VisibilityScheduleUserInAccountGroupsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "03d1b656bb1d20e90d867163c2ec8519",
    "id": null,
    "metadata": {},
    "name": "selectUserInAccountGroupsField_VisibilityScheduleUserInAccountGroupsQuery",
    "operationKind": "query",
    "text": "query selectUserInAccountGroupsField_VisibilityScheduleUserInAccountGroupsQuery(\n  $accountId: ID!\n) {\n  Admin {\n    VisibilitySchedule {\n      SearchUserInAccountGroupsForVisibilitySchedule(filterByAccountId: $accountId) {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3d2912a0bdce9fa06db9006786b75198";

export default node;
