/**
 * @generated SignedSource<<ebc70aa8289584cf4757e346704decf6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type selectAccountField_AccountOptionsFragment$data = ReadonlyArray<{
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "selectAccountField_AccountOptionsFragment";
}>;
export type selectAccountField_AccountOptionsFragment$key = ReadonlyArray<{
  readonly " $data"?: selectAccountField_AccountOptionsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"selectAccountField_AccountOptionsFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "selectAccountField_AccountOptionsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "8f761e12638bfd42cf362fee1a4af95b";

export default node;
