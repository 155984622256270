import { createContext, Dispatch } from "react";

export type IVisibilitySchedulesScreenContext = {
	connectionId: string;
	setConnectionId: Dispatch<string>;
};

export const VisibilitySchedulesScreenContext = createContext<IVisibilitySchedulesScreenContext>({
	connectionId: "",
	setConnectionId: () => {},
});
