import { graphql } from "babel-plugin-relay/macro";

export const CREATE_SINGLE_USER_VISIBILITY_SCHEDULE_MUTATION = graphql`
	mutation createVisibilityScheduleButton_CreateSingleUserVisibilityScheduleMutation(
		$input: CreateSingleUserVisibilityScheduleInput!
		$connections: [ID!]!
	) {
		Admin {
			VisibilitySchedule {
				createSingleUserVisibilitySchedule(input: $input) {
					visibilitySchedule @appendEdge(connections: $connections) {
						node {
							...visibilitySchedulesTable_VisibilitySchedule_VisibilityScheduleInlineFragment
						}
					}
				}
			}
		}
	}
`;
export const CREATE_USER_IN_ACCOUNT_GROUP_VISIBILITY_SCHEDULE_MUTATION = graphql`
	mutation createVisibilityScheduleButton_CreateUserInAccountGroupVisibilityScheduleMutation(
		$input: CreateUserInAccountGroupVisibilityScheduleInput!
		$connections: [ID!]!
	) {
		Admin {
			VisibilitySchedule {
				createUserInAccoutGroupVisibilitySchedule(input: $input) {
					visibilitySchedule @appendEdge(connections: $connections) {
						node {
							...visibilitySchedulesTable_VisibilitySchedule_VisibilityScheduleInlineFragment
						}
					}
				}
			}
		}
	}
`;
