import { flex } from "@styled-system/patterns";

export const dataKindWrapperClass = flex({
	gap: "16",
});

export const wrapperClass = flex({
	gap: "2",
});

export const headerWrapperClass = flex({
	justifyContent: "space-between",
	mb: "16",
});
