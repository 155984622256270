import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query selectAccountField_Query {
		...selectAccountField_QueryFragment
	}
`;

export const QUERY_FRAGMENT = graphql`
	fragment selectAccountField_QueryFragment on Query
	@refetchable(queryName: "selectAccountField_Refetch")
	@argumentDefinitions(
		first: { type: "Int" }
		after: { type: "String", defaultValue: null }
		nameOpt: { type: "String", defaultValue: null }
		ids: { type: "[ID!]", defaultValue: [] } # Optional array of IDs
	) {
		Admin {
			Auth {
				SelectAccounts(first: $first, after: $after, nameOpt: $nameOpt, ids: $ids)
					@connection(key: "selectAccountField_SelectAccounts") {
					edges {
						node {
							...selectAccountField_AccountFragment
						}
					}
				}
			}
		}
	}
`;

export const ACCOUNT_OPTIONS_FRAGMENT = graphql`
	fragment selectAccountField_AccountOptionsFragment on Account @relay(plural: true) {
		id
		name
	}
`;

export const ACCOUNT_FRAGMENT = graphql`
	fragment selectAccountField_AccountFragment on Account @inline {
		id
		name
	}
`;
