/**
 * @generated SignedSource<<41e8c84b9f0722f3442bd036abc97801>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddOnlyWithLicenseVisibilityTreeConfigInput = {
  clientMutationId?: string | null | undefined;
  rootNodeId: string;
};
export type visibilityTreeConfigEditor_AddOnlyWithLicenseVisibilityTreeConfigMutation$variables = {
  input: AddOnlyWithLicenseVisibilityTreeConfigInput;
};
export type visibilityTreeConfigEditor_AddOnlyWithLicenseVisibilityTreeConfigMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly addOnlyWithLicenseVisibilityTreeConfig: {
        readonly rootNode: {
          readonly " $fragmentSpreads": FragmentRefs<"visibilityTreeConfigEditor_TreeNodeFragment">;
        };
      } | null | undefined;
    };
  };
};
export type visibilityTreeConfigEditor_AddOnlyWithLicenseVisibilityTreeConfigMutation = {
  response: visibilityTreeConfigEditor_AddOnlyWithLicenseVisibilityTreeConfigMutation$data;
  variables: visibilityTreeConfigEditor_AddOnlyWithLicenseVisibilityTreeConfigMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "dateTime",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "visibilityTreeConfigEditor_AddOnlyWithLicenseVisibilityTreeConfigMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AddOnlyWithLicenseVisibilityTreeConfigPayload",
                "kind": "LinkedField",
                "name": "addOnlyWithLicenseVisibilityTreeConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "rootNode",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "visibilityTreeConfigEditor_TreeNodeFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "visibilityTreeConfigEditor_AddOnlyWithLicenseVisibilityTreeConfigMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AddOnlyWithLicenseVisibilityTreeConfigPayload",
                "kind": "LinkedField",
                "name": "addOnlyWithLicenseVisibilityTreeConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "rootNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "structureDefinition",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "visibilityConfigs",
                                "plural": true,
                                "selections": [
                                  (v3/*: any*/),
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "configType",
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": (v4/*: any*/),
                                    "type": "NotAfterDateTimeVisibilityTreeConfig",
                                    "abstractKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": (v4/*: any*/),
                                    "type": "AfterDateTimeVisibilityTreeConfig",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "RootStructureDefinition",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0967c4277336108c2f04995c39e6a7e0",
    "id": null,
    "metadata": {},
    "name": "visibilityTreeConfigEditor_AddOnlyWithLicenseVisibilityTreeConfigMutation",
    "operationKind": "mutation",
    "text": "mutation visibilityTreeConfigEditor_AddOnlyWithLicenseVisibilityTreeConfigMutation(\n  $input: AddOnlyWithLicenseVisibilityTreeConfigInput!\n) {\n  Admin {\n    Tree {\n      addOnlyWithLicenseVisibilityTreeConfig(input: $input) {\n        rootNode {\n          ...visibilityTreeConfigEditor_TreeNodeFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment AfterDateTimeVisibilityConfigForm_TreeNodeFragment on TreeNode {\n  id\n}\n\nfragment AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment on NotAfterDateTimeVisibilityTreeConfig {\n  id\n  dateTime\n  configType\n}\n\nfragment NotAfterDateTimeVisibilityConfigForm_TreeNodeFragment on TreeNode {\n  id\n}\n\nfragment NotAfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment on NotAfterDateTimeVisibilityTreeConfig {\n  id\n  dateTime\n  configType\n}\n\nfragment visibilityTreeConfigEditor_TreeNodeFragment on TreeNode {\n  id\n  structureDefinition {\n    __typename\n    ... on RootStructureDefinition {\n      visibilityConfigs {\n        __typename\n        id\n        configType\n        ... on NotAfterDateTimeVisibilityTreeConfig {\n          dateTime\n        }\n        ... on AfterDateTimeVisibilityTreeConfig {\n          dateTime\n        }\n        ...NotAfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment\n        ...AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment\n      }\n    }\n  }\n  ...NotAfterDateTimeVisibilityConfigForm_TreeNodeFragment\n  ...AfterDateTimeVisibilityConfigForm_TreeNodeFragment\n}\n"
  }
};
})();

(node as any).hash = "4c29ec9f3b8f566827432c6b3792c5f9";

export default node;
