import { graphql } from "babel-plugin-relay/macro";

export const VISISIBILITY_SCHEDULE_VISIBILITY_SCHEDULE_FRAGMENT = graphql`
	fragment deleteVisibilityScheduleButton_VisibilitySchedule_VisibilityScheduleFragment on VisibilitySchedule_VisibilitySchedule {
		id
	}
`;

export const DELETE_VISIBILITY_SCHEDULE_MUTATION = graphql`
	mutation deleteVisibilityScheduleButton_DeleteVisibilityScheduleMutation(
		$input: DeleteVisibilityScheduleInput!
		$connections: [ID!]!
	) {
		Admin {
			VisibilitySchedule {
				deleteVisibilitySchedule(input: $input) {
					deletedIds @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;
