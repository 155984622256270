/**
 * @generated SignedSource<<c7d9fc21dc8a43b6802d4aa1e306b4fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type selectUserInAccountGroupFieldDialog_VisibilityScheduleUserInAccountGroupQuery$variables = {
  userInAccountGroupId: string;
};
export type selectUserInAccountGroupFieldDialog_VisibilityScheduleUserInAccountGroupQuery$data = {
  readonly Admin: {
    readonly VisibilitySchedule: {
      readonly GetUserInAccountGroupForVisibilitySchedule: {
        readonly name: string;
      } | null | undefined;
    };
  };
};
export type selectUserInAccountGroupFieldDialog_VisibilityScheduleUserInAccountGroupQuery = {
  response: selectUserInAccountGroupFieldDialog_VisibilityScheduleUserInAccountGroupQuery$data;
  variables: selectUserInAccountGroupFieldDialog_VisibilityScheduleUserInAccountGroupQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userInAccountGroupId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "userInAccountGroupId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "selectUserInAccountGroupFieldDialog_VisibilityScheduleUserInAccountGroupQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VisibilityScheduleAdminSchema",
            "kind": "LinkedField",
            "name": "VisibilitySchedule",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UserInAccountGroup",
                "kind": "LinkedField",
                "name": "GetUserInAccountGroupForVisibilitySchedule",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "selectUserInAccountGroupFieldDialog_VisibilityScheduleUserInAccountGroupQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VisibilityScheduleAdminSchema",
            "kind": "LinkedField",
            "name": "VisibilitySchedule",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UserInAccountGroup",
                "kind": "LinkedField",
                "name": "GetUserInAccountGroupForVisibilitySchedule",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4dd1bac86a2a0f68bebbd97aaf348750",
    "id": null,
    "metadata": {},
    "name": "selectUserInAccountGroupFieldDialog_VisibilityScheduleUserInAccountGroupQuery",
    "operationKind": "query",
    "text": "query selectUserInAccountGroupFieldDialog_VisibilityScheduleUserInAccountGroupQuery(\n  $userInAccountGroupId: ID!\n) {\n  Admin {\n    VisibilitySchedule {\n      GetUserInAccountGroupForVisibilitySchedule(id: $userInAccountGroupId) {\n        name\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6954c0123a9335baddbb0d488c08c4ad";

export default node;
