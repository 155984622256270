import { graphql } from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment visibilitySchedulesTable_QueryFragment on Query
	@refetchable(queryName: "visibilitySchedulesTable_Refetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 20 }
		after: { type: "String" }
		last: { type: "Int" }
		before: { type: "String" }
		accountId: { type: "ID" }
		rootId: { type: "ID!" }
		dataKinds: { type: "[VisibilitySchedule_VisibilityScheduleDataKind!]!" }
	) {
		Admin {
			VisibilitySchedule {
				SearchVisibilitySchedules(
					first: $first
					after: $after
					last: $last
					before: $before
					accountId: $accountId
					rootId: $rootId
					dataKinds: $dataKinds
				)
					@connection(
						key: "visibilitySchedulesTable_VisibilitySchedule_SearchVisibilitySchedules"
					) {
					__id
					edges {
						node {
							...visibilitySchedulesTable_VisibilitySchedule_VisibilityScheduleInlineFragment
						}
					}
					pageInfo {
						startCursor
						endCursor
						hasNextPage
						hasPreviousPage
					}
				}
			}
		}
	}
`;

export const VISIBILITY_SCHEDULE_INLINE_FRAGMENT = graphql`
	fragment visibilitySchedulesTable_VisibilitySchedule_VisibilityScheduleInlineFragment on VisibilitySchedule_VisibilitySchedule
	@inline {
		id
		account {
			name
		}
		visibleFrom
		visibleUntil
		data {
			... on VisibilityScheduleData {
				kind
				... on SingleUserVisibilitySchedule {
					users {
						name
					}
				}
				... on UserInAccountGroupVisibilitySchedule {
					userInAccountGroups {
						name
					}
				}
			}
		}
		...deleteVisibilityScheduleButton_VisibilitySchedule_VisibilityScheduleFragment
	}
`;
