import * as Yup from "yup";
import { SelectAccountFieldState } from "@features/accounts/select-account-field";
import { VisibilitySchedule_VisibilityScheduleDataKind } from "@relay/visibilitySchedules_Query.graphql";

const VisibilityScheduleDataKinds = Object.values<VisibilitySchedule_VisibilityScheduleDataKind>({
	SingleUserData: "SingleUserData",
	UserInAccountGroupData: "UserInAccountGroupData",
});

export const getVisibilityScheduleFormSchema = (isRootAccount: boolean) =>
	Yup.object({
		visibleFrom: Yup.string()
			.required("'Sichtbar ab' wird benötigt.")
			.test(
				"visibleFrom-before-visibleUntil",
				"'Sichtbar ab' muss vor 'Sichtbar bis' liegen.",
				function (value) {
					const { visibleUntil } = this.parent;
					if (!value || !visibleUntil) return true;
					return new Date(value) < new Date(visibleUntil);
				},
			),
		visibleUntil: Yup.string().required("'Sichtbar bis' wird benötigt."),
		dataKind: Yup.mixed<VisibilitySchedule_VisibilityScheduleDataKind>()
			.oneOf(VisibilityScheduleDataKinds)
			.required("Eine Kurs-Verfügbarkeit-Art muss ausgewählt werden."),
		userId: Yup.array()
			.of(Yup.string().required("Ein Benutzer muss angegeben werden."))
			.when("dataKind", {
				is: (dataKind: VisibilitySchedule_VisibilityScheduleDataKind) =>
					dataKind === "SingleUserData",
				then: (schema) => {
					return schema
						.required("Ein Benutzer muss angegeben werden.")
						.min(1, "Es muss mindestens ein Benutzer angegeben werden.");
				},
			}),
		userInAccountGroupId: Yup.array()
			.of(Yup.string().required("Eine Nutzergruppe muss angegeben werden."))
			.when("dataKind", {
				is: (dataKind: VisibilitySchedule_VisibilityScheduleDataKind) =>
					dataKind === "UserInAccountGroupData",
				then: (schema) => {
					return schema
						.required("Eine Nutzergruppe muss angegeben werden.")
						.min(1, "Es muss mindestens eine Nutzergruppe angegeben werden.");
				},
			}),
		account: Yup.mixed<SelectAccountFieldState>().when([], () => {
			return isRootAccount
				? Yup.mixed<SelectAccountFieldState>().required("Ein Account ist erforderlich.")
				: Yup.mixed<SelectAccountFieldState>().notRequired();
		}),
	});
