/**
 * @generated SignedSource<<1a0130c66071e7a029b41c15e0d17708>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type selectUserFieldDialog_VisibilityScheduleUserQuery$variables = {
  userId: string;
};
export type selectUserFieldDialog_VisibilityScheduleUserQuery$data = {
  readonly Admin: {
    readonly VisibilitySchedule: {
      readonly GetUserForVisibilitySchedule: {
        readonly email: string;
        readonly name: string;
      };
    };
  };
};
export type selectUserFieldDialog_VisibilityScheduleUserQuery = {
  response: selectUserFieldDialog_VisibilityScheduleUserQuery$data;
  variables: selectUserFieldDialog_VisibilityScheduleUserQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "userId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "selectUserFieldDialog_VisibilityScheduleUserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VisibilityScheduleAdminSchema",
            "kind": "LinkedField",
            "name": "VisibilitySchedule",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "GetUserForVisibilitySchedule",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "selectUserFieldDialog_VisibilityScheduleUserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VisibilityScheduleAdminSchema",
            "kind": "LinkedField",
            "name": "VisibilitySchedule",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "GetUserForVisibilitySchedule",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cb20b4d35729aa3cfe67e25ad786c433",
    "id": null,
    "metadata": {},
    "name": "selectUserFieldDialog_VisibilityScheduleUserQuery",
    "operationKind": "query",
    "text": "query selectUserFieldDialog_VisibilityScheduleUserQuery(\n  $userId: ID!\n) {\n  Admin {\n    VisibilitySchedule {\n      GetUserForVisibilitySchedule(id: $userId) {\n        name\n        email\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "25991bac758482217d644dacc9990305";

export default node;
