import { EpicIcons, InputText } from "@thekeytechnology/epic-ui";
import { Column } from "primereact/column";
import { DataTable, type DataTableSelectionSingleChangeEvent } from "primereact/datatable";

import { useCallback, useEffect, useState } from "react";
import { useRelayEnvironment } from "react-relay";
import { fetchQuery } from "relay-runtime";
import { Button } from "@components/button";
import { inputWrapperClass } from "@features/users/select-user-field/select-user-field.styles";
import { type selectUserField_EditorsQuery } from "@relay/selectUserField_EditorsQuery.graphql";
import { type selectUserField_UsersQuery } from "@relay/selectUserField_UsersQuery.graphql";
import { selectUserField_VisibilityScheduleUsersQuery } from "@relay/selectUserField_VisibilityScheduleUsersQuery.graphql";
import {
	EDITORS_QUERY,
	USERS_QUERY,
	VISIBILITY_SCHEDULE_USERS_QUERY,
} from "./select-user-field.graphql";
import {
	EditorsQueryData,
	type SelectUserFieldProps,
	UsersQueryData,
	VisibilityScheduleUsersQueryData,
} from "./select-user-field.types";

export const SelectUserField = ({
	updateField,
	fieldValue: selectedUserId,
	selectEditors,
	selectVisibilityScheduleUsers,
	accountId,
}: SelectUserFieldProps) => {
	const environment = useRelayEnvironment();

	const [nameOrEmail, setNameOrEmail] = useState("");
	const [allUsers, setAllUsers] = useState<
		UsersQueryData | EditorsQueryData | VisibilityScheduleUsersQueryData | undefined
	>();

	const updateUsers = useCallback(async (updateNameOrEmail: string, accountId?: string) => {
		if (selectEditors) {
			const result = await fetchQuery<selectUserField_EditorsQuery>(
				environment,
				EDITORS_QUERY,
				{
					nameOrEmail: updateNameOrEmail,
				},
			).toPromise();
			setAllUsers(result?.Admin.Auth.SearchEditors.edges?.slice());
		} else if (selectVisibilityScheduleUsers) {
			const result = await fetchQuery<selectUserField_VisibilityScheduleUsersQuery>(
				environment,
				VISIBILITY_SCHEDULE_USERS_QUERY,
				{
					nameOrEmail: updateNameOrEmail,
					accountId,
				},
			).toPromise();
			setAllUsers(
				result?.Admin.VisibilitySchedule.SearchUsersForVisibilitySchedule.edges?.slice(),
			);
		} else {
			const result = await fetchQuery<selectUserField_UsersQuery>(environment, USERS_QUERY, {
				nameOrEmail: updateNameOrEmail,
				accountId,
			}).toPromise();
			setAllUsers(result?.Admin.Auth.SelectUsers.edges?.slice());
		}
	}, []);

	const handleOnSubmit = useCallback(() => {
		updateUsers(nameOrEmail, accountId);
	}, [nameOrEmail, updateUsers, accountId]);

	useEffect(handleOnSubmit, [accountId]);

	useEffect(() => {
		if (selectedUserId && !allUsers?.find((user) => user?.node.id === selectedUserId)) {
			updateField(undefined);
		}
	}, [allUsers]);

	const handleClearFormOnClick = () => {
		setNameOrEmail("");
		updateUsers("", accountId);
	};

	const handelNameOrEmailChange = useCallback((value?: string) => {
		if (!value) return;
		setNameOrEmail(value);
	}, []);

	const handleUserSelected = useCallback((event: DataTableSelectionSingleChangeEvent<any>) => {
		updateField(event.value.node.id);
	}, []);

	return (
		<>
			<div className={inputWrapperClass}>
				<InputText value={nameOrEmail} onChange={handelNameOrEmailChange} />
				<Button onClick={handleClearFormOnClick} icon={EpicIcons.TIMES} />
				<Button onClick={handleOnSubmit} icon={EpicIcons.SEARCH} />
			</div>
			<DataTable
				emptyMessage={
					"Es wurde kein Benutzer mit dieser E-Mail adresse oder diesem Namen	gefunden."
				}
				selectionMode="single"
				selection={{ node: { id: selectedUserId } }}
				dataKey="node.id"
				onSelectionChange={handleUserSelected}
				value={allUsers ?? []}
			>
				<Column header="Name" field="node.name" />
				<Column header="E-Mail" field="node.email" />
				<Column
					header="Aktiviert"
					body={(user) => {
						return user?.node?.activated ? "Aktiviert" : "Nicht Aktiviert";
					}}
				/>
			</DataTable>
		</>
	);
};
