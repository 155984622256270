import { type FormikProps, useFormik } from "formik";

import React, { useImperativeHandle } from "react";
import * as Yup from "yup";
import { type createCoachProfileForm_QueryFragment$key } from "@relay/createCoachProfileForm_QueryFragment.graphql";
import {
	type CreateCoachProfileFormState,
	type FormProps,
} from "./create-coach-profile-form.types";
import { ValidatedField } from "../../../../components/ValidatedField";
import { SelectAccountField } from "../../../../features/accounts/select-account-field";
import { type SelectAccountFieldState } from "../../../../features/accounts/select-account-field/select-account-field.types";
import { SelectUserField } from "../../../../features/users/select-user-field";

export const CreateCoachProfileForm = React.forwardRef<
	FormikProps<CreateCoachProfileFormState>,
	FormProps<CreateCoachProfileFormState> & {
		queryFragmentRef: createCoachProfileForm_QueryFragment$key;
	}
>(({ initialState, onSubmit }, ref) => {
	const formik = useFormik<CreateCoachProfileFormState>({
		initialValues: initialState ?? {
			account: {
				id: "",
				name: "",
			},
			userId: "",
			baseData: {
				address: { city: "", lineOne: "", lineTwo: "", postalCode: "" },
				birthDate: "",
				birthPlace: "",
				gender: "Male",
				nationality: "",
			},
		},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			account: Yup.object().required(),
		}),
		onSubmit,
	});

	useImperativeHandle(ref, () => ({
		...formik,
	}));

	return (
		<form onSubmit={formik.handleSubmit} className={"p-fluid w-30rem"}>
			<ValidatedField<CreateCoachProfileFormState, SelectAccountFieldState>
				name={"account"}
				label={"Account"}
				formikConfig={formik}
				component={(renderConfig) => <SelectAccountField {...renderConfig} />}
			/>
			{formik.values?.account?.id ? (
				<ValidatedField<CreateCoachProfileFormState, string>
					name={"userId"}
					label={"Nutzer"}
					formikConfig={formik}
					component={(renderConfig) => (
						<SelectUserField {...renderConfig} accountId={formik.values?.account?.id} />
					)}
				/>
			) : (
				<div>Konto auswählen um fortzufahren.</div>
			)}
		</form>
	);
});
CreateCoachProfileForm.displayName = "CreateCoachProfileForm";
