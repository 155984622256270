import { useFormik } from "formik";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { SelectUserInAccountGroupsField } from "@features/accounts/select-user-in-account-groups-field";
import {
	type EditUserInAccountGroupFormProps,
	type EditUserInAccountGroupFormState,
} from "./edit-user-in-account-group-form.interface";
import { ValidatedField } from "../../../components/ValidatedField";

export const EditUserInAccountGroupForm = ({
	groupsId,
	onSubmitted,
	accountId,
}: EditUserInAccountGroupFormProps) => {
	const formik = useFormik<EditUserInAccountGroupFormState>({
		initialValues: { groupsId },
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			groupsId: Yup.array().of(Yup.string()),
		}),
		onSubmit: (data, { setSubmitting }) => {
			onSubmitted(data, () => {
				setSubmitting(false);
			});
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} className="w-12 p-fluid mb-5">
			<ValidatedField<EditUserInAccountGroupFormState, string[]>
				className="mb-4"
				name={"groupsId"}
				label={"Gruppen bearbeiten"}
				formikConfig={formik}
				placeholder={"Gruppe auswählen"}
				component={(config) => (
					<SelectUserInAccountGroupsField {...config} accountId={accountId} />
				)}
			/>
			<Button type="submit" label="Gruppen speichern" />
		</form>
	);
};
