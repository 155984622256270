/**
 * @generated SignedSource<<8a08a16c0bddb8ce38fed73050d072ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editVisibilitySchedulesButton_LearnOpportunityV2ragment$data = {
  readonly id: string;
  readonly " $fragmentType": "editVisibilitySchedulesButton_LearnOpportunityV2ragment";
};
export type editVisibilitySchedulesButton_LearnOpportunityV2ragment$key = {
  readonly " $data"?: editVisibilitySchedulesButton_LearnOpportunityV2ragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editVisibilitySchedulesButton_LearnOpportunityV2ragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editVisibilitySchedulesButton_LearnOpportunityV2ragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};

(node as any).hash = "855f2e9d46140d270277a6b2c505548f";

export default node;
