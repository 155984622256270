import { EpicIcons, Icon, RelayPageDataTable } from "@thekeytechnology/epic-ui";
import { ReactNode, useCallback, useContext } from "react";
import { useRefetchableFragment } from "react-relay";
import { match } from "ts-pattern";
import { formatDate } from "@components/DateTimeDisplay";

import { useCheckPermissions } from "@hooks/use-check-permissions";
import { visibilitySchedulesTable_QueryFragment$key } from "@relay/visibilitySchedulesTable_QueryFragment.graphql";
import { visibilitySchedulesTable_Refetch } from "@relay/visibilitySchedulesTable_Refetch.graphql";
import {
	visibilitySchedulesTable_VisibilitySchedule_VisibilityScheduleInlineFragment$data,
	visibilitySchedulesTable_VisibilitySchedule_VisibilityScheduleInlineFragment$key,
} from "@relay/visibilitySchedulesTable_VisibilitySchedule_VisibilityScheduleInlineFragment.graphql";
import { DeleteVisibilityScheduleButton } from "@screens/visibility-schedules/parts/delete-visibility-schedule-button/delete-visibility-schedule-button.component";
import { dataKindWrapperClass } from "@screens/visibility-schedules/parts/visibility-schedules-table/visibility-schedules-table.styles";
import { VisibilitySchedulesScreenContext } from "@screens/visibility-schedules/visibility-schedules.context";
import { DEFAULT_ROWS_COUNT } from "./visibility-schedules-table.consts";
import {
	QUERY_FRAGMENT,
	VISIBILITY_SCHEDULE_INLINE_FRAGMENT,
} from "./visibility-schedules-table.graphql";
import { VisibilitySchedulesTableProps } from "./visibility-schedules-table.types";

export const VisibilitySchedulesTable = ({ queryFragmentRef }: VisibilitySchedulesTableProps) => {
	const { setConnectionId } = useContext(VisibilitySchedulesScreenContext);
	const checkPermissions = useCheckPermissions();
	const isRootAccount = checkPermissions(["AccountPermission_System_Root"]);

	const [_, refetch] = useRefetchableFragment<
		visibilitySchedulesTable_Refetch,
		visibilitySchedulesTable_QueryFragment$key
	>(QUERY_FRAGMENT, queryFragmentRef);

	const refresh = useCallback(() => {
		refetch({}, { fetchPolicy: "network-only" });
	}, [refetch]);

	const handleOnConnectionIdChange = (id?: string | null) => {
		id && setConnectionId(id);
	};

	const getUserOrUserInAccountGroup = (
		visibilitySchedule: visibilitySchedulesTable_VisibilitySchedule_VisibilityScheduleInlineFragment$data,
	): ReactNode => {
		const icon = (
			<Icon
				icon={
					visibilitySchedule.data.kind === "SingleUserData"
						? EpicIcons.USER
						: EpicIcons.USERS
				}
			/>
		);

		const name = match(visibilitySchedule.data.kind)
			.with("SingleUserData", () => visibilitySchedule.data.users![0].name)
			.with(
				"UserInAccountGroupData",
				() => visibilitySchedule.data.userInAccountGroups![0].name,
			)
			.exhaustive();

		return (
			<div className={dataKindWrapperClass}>
				{icon} {name}
			</div>
		);
	};

	return (
		<RelayPageDataTable<
			visibilitySchedulesTable_Refetch,
			visibilitySchedulesTable_QueryFragment$key,
			visibilitySchedulesTable_VisibilitySchedule_VisibilityScheduleInlineFragment$key
		>
			queryFragment={QUERY_FRAGMENT}
			parentFragmentRef={queryFragmentRef}
			nodeInlineFragment={VISIBILITY_SCHEDULE_INLINE_FRAGMENT}
			extractConnection={(data) => data.Admin.VisibilitySchedule.SearchVisibilitySchedules}
			rowsCount={DEFAULT_ROWS_COUNT}
			onConnectionIdChange={handleOnConnectionIdChange}
		>
			{(Column) => (
				<>
					{isRootAccount && (
						<Column
							header={"Konto"}
							body={(visibilitySchedule) => visibilitySchedule.account.name}
						/>
					)}
					<Column
						header={"Nutzer/Nutzergruppe"}
						body={(visibilitySchedule) => (
							<>{getUserOrUserInAccountGroup(visibilitySchedule)}</>
						)}
					/>
					<Column
						header="Sichtbar ab"
						body={(visibilitySchedule) => formatDate(visibilitySchedule.visibleFrom)}
					/>
					<Column
						header={"Sichtbar bis"}
						body={(visibilitySchedule) => formatDate(visibilitySchedule.visibleUntil)}
					/>

					<Column
						header="Aktionen"
						style={{ width: "15%" }}
						body={(visibilitySchedule) => (
							<DeleteVisibilityScheduleButton
								visibilityScheduleFragmentRef={visibilitySchedule}
								onRefresh={refresh}
							/>
						)}
					/>
				</>
			)}
		</RelayPageDataTable>
	);
};
