import { EpicIcons } from "@thekeytechnology/epic-ui";
import { Toast } from "primereact/toast";
import React, { useContext, useRef } from "react";
import { useFragment, useMutation } from "react-relay";
import { Button, ButtonVariant } from "@components/button";
import { deleteVisibilityScheduleButton_DeleteVisibilityScheduleMutation } from "@relay/deleteVisibilityScheduleButton_DeleteVisibilityScheduleMutation.graphql";
import { deleteVisibilityScheduleButton_VisibilitySchedule_VisibilityScheduleFragment$key } from "@relay/deleteVisibilityScheduleButton_VisibilitySchedule_VisibilityScheduleFragment.graphql";
import {
	DELETE_VISIBILITY_SCHEDULE_MUTATION,
	VISISIBILITY_SCHEDULE_VISIBILITY_SCHEDULE_FRAGMENT,
} from "@screens/visibility-schedules/parts/delete-visibility-schedule-button/delete-visibility-schedule-button.graphql";
import { DeleteVisibilityScheduleButtonProps } from "@screens/visibility-schedules/parts/delete-visibility-schedule-button/delete-visibility-schedule-button.types";
import { VisibilitySchedulesScreenContext } from "@screens/visibility-schedules/visibility-schedules.context";

export const DeleteVisibilityScheduleButton = ({
	visibilityScheduleFragmentRef,
	onRefresh,
}: DeleteVisibilityScheduleButtonProps) => {
	const toast = useRef<Toast>(null);
	const { connectionId } = useContext(VisibilitySchedulesScreenContext);

	const visibilitySchedule =
		useFragment<deleteVisibilityScheduleButton_VisibilitySchedule_VisibilityScheduleFragment$key>(
			VISISIBILITY_SCHEDULE_VISIBILITY_SCHEDULE_FRAGMENT,
			visibilityScheduleFragmentRef,
		);

	const [deleteVisibilitySchedule, isDeletingVisibilitySchedule] =
		useMutation<deleteVisibilityScheduleButton_DeleteVisibilityScheduleMutation>(
			DELETE_VISIBILITY_SCHEDULE_MUTATION,
		);

	const handleDeleteVisibilitySchedule = () =>
		deleteVisibilitySchedule({
			variables: {
				input: {
					ids: [visibilitySchedule.id],
				},
				connections: [connectionId],
			},
			onCompleted: () => {
				toast.current?.show({
					severity: "success",
					life: 3000,
					summary: "Kurs-Verfügbarkeit gelöscht",
					detail: "Die Kurs-Verfügbarkeit wurde erfolgreich gelöscht",
				});
				onRefresh();
			},
		});

	return (
		<>
			<Toast ref={toast} />
			<Button
				icon={EpicIcons.TRASH}
				variant={ButtonVariant.Error}
				tooltip="Kurs-Verfügbarkeit löschen"
				loading={isDeletingVisibilitySchedule}
				disabled={isDeletingVisibilitySchedule}
				onClick={handleDeleteVisibilitySchedule}
			/>
		</>
	);
};
