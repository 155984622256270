import { LocalDate, ZonedDateTime } from "@js-joda/core";
import moment from "moment-timezone";

type OwnProps = {
	value?: string | null;
	hideTimezone?: boolean;
};

export const formatDate = (value: string): string => {
	const date = LocalDate.parse(value);
	return moment(date.toString()).format("DD.MM.YYYY");
};

export const formatDateTime = (value: string) => {
	const date: ZonedDateTime = ZonedDateTime.parse(value);
	return moment(date.toOffsetDateTime().toString())
		.tz(moment.tz.guess())
		.format("DD.MM.YYYY HH:mm");
};

export const DateTimeDisplay = ({ value, hideTimezone }: OwnProps) => {
	if (!value) {
		return null;
	}

	const formattedDateTime = formatDateTime(value);

	if (hideTimezone) {
		return <span>{formattedDateTime}</span>;
	}

	return (
		<div>
			<div>{formattedDateTime}</div>
			{!hideTimezone && <small>{moment.tz.guess()}</small>}
		</div>
	);
};
