import { useFormik } from "formik";
import moment from "moment/moment";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { Suspense, useRef } from "react";
import { useFragment, useMutation } from "react-relay";
import * as Yup from "yup";
import {
	DefaultDecimalNumberFieldComponent,
	DefaultTextFieldComponent,
} from "@components/DefaultTextInput";
import { ValidatedField } from "@components/ValidatedField";
import { type discountActionForm_DiscountActionFragment$key } from "@relay/discountActionForm_DiscountActionFragment.graphql";
import {
	type discountActionForm_EditPercentageDiscountActionMutation,
	type UpdateUsageLimited,
} from "@relay/discountActionForm_EditPercentageDiscountActionMutation.graphql";
import { DiscountTypeKindInput } from "@relay/discountActionsScreen_Query.graphql";
import {
	DISCOUNT_ACTION_FRAGMENT,
	EDIT_PERCENTAGE_DISCOUNT_ACTION_MUTATION,
} from "./discount-action-form.graphql";
import {
	type DiscountActionFormProps,
	type DiscountActionFormState,
} from "./discount-action-form.types";
import { DiscountTypeInput } from "../DiscountTypeInput";
import { UsageLimitationInput } from "../UsageLimitationInput";

export const DiscountActionForm = ({ discountActionFragmentRef }: DiscountActionFormProps) => {
	const toast = useRef<Toast>(null);

	const discountAction = useFragment<discountActionForm_DiscountActionFragment$key>(
		DISCOUNT_ACTION_FRAGMENT,
		discountActionFragmentRef,
	);

	const [editPercentageDiscountAction, isEditingPercentageDiscountAction] =
		useMutation<discountActionForm_EditPercentageDiscountActionMutation>(
			EDIT_PERCENTAGE_DISCOUNT_ACTION_MUTATION,
		);

	const formik = useFormik<DiscountActionFormState>({
		initialValues: {
			id: discountAction.id,
			title: discountAction.title,
			discountType: discountAction.discountType.kind,
			percentage: discountAction.calcType.percentage || 0,
			validUntil: discountAction.validUntil.dateTime,
			usageLimitation:
				discountAction.usageLimitation.kind === "limited"
					? {
							maxAmountOfUsages:
								discountAction.usageLimitation.maxAmountOfUsages?.max,
							maxAmountOfUsagesPerAccount:
								discountAction.usageLimitation.maxAmountOfUsagesPerAccount?.max,
							onlyForProductIds:
								discountAction.usageLimitation.onlyForProductIds?.map((l) => l) ??
								[],
							onlyForAccountIds:
								discountAction.usageLimitation.onlyForAccountIds?.map((l) => l) ??
								[],
					  }
					: undefined,
		},
		validationSchema: Yup.object().shape({
			percentage: Yup.number()
				.max(100, "Der Wert muss kleiner als 100% sein")
				.min(1, "Der Wert muss mindestens 1% sein"),
		}),
		onSubmit: (values, { setSubmitting }) => {
			editPercentageDiscountAction({
				variables: {
					input: {
						discountActionId: discountAction.id,
						newTitle: values.title,
						newDiscountType: values.discountType as DiscountTypeKindInput,
						newPercentage: values.percentage,
						newValidUntil: values.validUntil,
						newUsageLimitation: values.usageLimitation,
					},
				},
				onCompleted: () => {
					toast.current?.show({
						severity: "success",
						life: 3000,
						summary: "Discount-Aktion",
						detail: "Erfolgreich gespeichert",
					});
				},
			});
			setSubmitting(false);
		},
	});

	return (
		<Card>
			<Toast ref={toast} />
			<Suspense fallback={<ProgressSpinner />}>
				{!isEditingPercentageDiscountAction ? (
					<form onSubmit={formik.handleSubmit} className="p-fluid">
						<div className="flex flex-column">
							<Button
								disabled={false}
								type="submit"
								icon="pi pi-save"
								label="Speichern"
								className="ml-auto w-8rem"
							/>
							<ValidatedField<DiscountActionFormState, string>
								name={"title"}
								label="Titel"
								required={true}
								component={DefaultTextFieldComponent}
								formikConfig={formik}
							/>
							<ValidatedField<DiscountActionFormState, string>
								name={"discountType"}
								label="Discount-Typ"
								component={({ fieldValue, updateField }) => (
									<DiscountTypeInput
										fieldValue={fieldValue}
										updateField={updateField}
									/>
								)}
								formikConfig={formik}
							/>
							<ValidatedField<DiscountActionFormState, number>
								name={"percentage"}
								label="Wert in %"
								component={DefaultDecimalNumberFieldComponent}
								formikConfig={formik}
							/>
							<ValidatedField<DiscountActionFormState, string>
								name={"validUntil"}
								label="Gültig bis"
								component={({ fieldName, fieldValue, updateField }) => (
									<Calendar
										name={fieldName}
										dateFormat={"dd.mm.yy"}
										showTime
										showButtonBar
										value={
											fieldValue
												? moment(fieldValue.replace("[UTC]", "")).toDate()
												: undefined
										}
										onChange={(e) => {
											updateField(
												e.value
													? moment(e.value as Date).toISOString(true)
													: undefined,
											);
										}}
									/>
								)}
								formikConfig={formik}
							/>
							<Card>
								<ValidatedField<DiscountActionFormState, UpdateUsageLimited>
									name={"usageLimitation"}
									label="Beschränkungen"
									component={({ fieldValue, updateField }) => (
										<UsageLimitationInput
											fieldValue={fieldValue}
											update={updateField}
										/>
									)}
									formikConfig={formik}
								/>
							</Card>
						</div>
					</form>
				) : (
					<ProgressSpinner />
				)}
			</Suspense>
		</Card>
	);
};
