import { Dialog, EpicIcons } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { useRelayEnvironment } from "react-relay";

import { fetchQuery } from "relay-runtime";
import { Button } from "@components/button";

import { SelectUserInAccountGroupFieldDialogSchema } from "@features/accounts/select-user-in-account-group-field-dialog/select-user-in-account-group-field-dialog.consts";
import { inputWrapperClass } from "@features/accounts/select-user-in-account-group-field-dialog/select-user-in-account-group-field-dialog.styles";
import { selectUserInAccountGroupFieldDialog_Query } from "@relay/selectUserInAccountGroupFieldDialog_Query.graphql";

import { selectUserInAccountGroupFieldDialog_VisibilityScheduleUserInAccountGroupQuery } from "@relay/selectUserInAccountGroupFieldDialog_VisibilityScheduleUserInAccountGroupQuery.graphql";
import { SelectUserInAccountGroupsField } from "src/features/accounts/select-user-in-account-groups-field";
import {
	QUERY,
	VISIBILITY_SCHEDULE_USER_IN_ACCOUNT_GROUP_QUERY,
} from "./select-user-in-account-group-field-dialog.graphql";
import {
	SelectUserInAccountGroupFieldDialogProps,
	SelectUserInAccountGroupFieldDialogState,
	UserInAccountGroupQueryData,
	VisiblityScheduleUserInAccountGroupQueryData,
} from "./select-user-in-account-group-field-dialog.types";

import { ValidatedField } from "../../../components/ValidatedField";

export const SelectUserInAccountGroupFieldDialog = ({
	updateField,
	fieldValue: selectedUserInAccountGroupIds,
	disabled,
	onChange,
	accountId,
	selectVisibilityScheduleUsers,
}: SelectUserInAccountGroupFieldDialogProps) => {
	const [isModalOpen, setModalOpen] = useState(false);
	const [userInAccountGroup, setUserInAccountGroup] = useState<
		VisiblityScheduleUserInAccountGroupQueryData | UserInAccountGroupQueryData | undefined
	>();
	const environment = useRelayEnvironment();

	const fetchUserInAccountGroupData = async (userInAccountGroupId: string | undefined) => {
		if (!userInAccountGroupId) return;

		if (selectVisibilityScheduleUsers) {
			const result =
				await fetchQuery<selectUserInAccountGroupFieldDialog_VisibilityScheduleUserInAccountGroupQuery>(
					environment,
					VISIBILITY_SCHEDULE_USER_IN_ACCOUNT_GROUP_QUERY,
					{
						userInAccountGroupId,
					},
				).toPromise();
			setUserInAccountGroup(
				result?.Admin?.VisibilitySchedule?.GetUserInAccountGroupForVisibilitySchedule,
			);
		} else {
			const result = await fetchQuery<selectUserInAccountGroupFieldDialog_Query>(
				environment,
				QUERY,
				{
					userInAccountGroupId,
					skip: !userInAccountGroupId,
				},
			).toPromise();
			setUserInAccountGroup(result?.node);
		}
	};

	const formik = useFormik<SelectUserInAccountGroupFieldDialogState>({
		initialValues: {
			userInAccountGroupIds: selectedUserInAccountGroupIds ?? [],
		},
		validationSchema: SelectUserInAccountGroupFieldDialogSchema,
		onSubmit: (values) => {
			updateField(values.userInAccountGroupIds);
			fetchUserInAccountGroupData(values.userInAccountGroupIds?.[0]);
			setModalOpen(false);
			onChange?.();
		},
	});

	const handleDialogOnHide = () => {
		setModalOpen(false);
	};

	const handleSelectUserInAccountGroupOnClick = () => {
		setModalOpen(true);
	};

	const handleUnselectUserInAccountGroupOnClick = () => {
		updateField(undefined);
	};

	const name = userInAccountGroup?.name ?? "Keine Nutzergruppe ausgewählt";

	return (
		<>
			<div className={inputWrapperClass}>
				<InputText
					onClick={handleSelectUserInAccountGroupOnClick}
					disabled={true}
					value={name}
				/>
				<Button
					disabled={selectedUserInAccountGroupIds?.length! <= 0}
					icon={EpicIcons.TIMES}
					onClick={handleUnselectUserInAccountGroupOnClick}
				/>
				<Button
					label="Nutzergruppe auswählen"
					disabled={disabled}
					onClick={handleSelectUserInAccountGroupOnClick}
				/>
			</div>
			<Dialog
				headerNode="Nutzergruppe auswählen"
				onHide={handleDialogOnHide}
				visible={isModalOpen}
			>
				<form onSubmit={formik.handleSubmit}>
					<ValidatedField<SelectUserInAccountGroupFieldDialogState, string[]>
						name={"userInAccountGroupIds"}
						label={"Wähle eine Nutzergruppe aus"}
						formikConfig={{
							...formik,
							errors: { ...formik.errors, userInAccountGroupIds: undefined },
						}}
						component={(config) => {
							return (
								<SelectUserInAccountGroupsField
									accountId={accountId}
									singleSelection
									selectVisibilityScheduleUsers={selectVisibilityScheduleUsers}
									{...config}
								/>
							);
						}}
					/>
					<Button
						disabled={!formik.isValid}
						onClick={formik.submitForm}
						label={"Auswählen"}
						icon={EpicIcons.CHECK}
					/>
				</form>
			</Dialog>
		</>
	);
};
