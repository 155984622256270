/**
 * @generated SignedSource<<7ffd1e77cf60e2f98422068a1370c1b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type coursesWithVisibilityConfigsTable_LearnOpportunityV2InlineFragment$data = {
  readonly structureDefinition: {
    readonly title: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"editVisibilitySchedulesButton_LearnOpportunityV2ragment">;
  readonly " $fragmentType": "coursesWithVisibilityConfigsTable_LearnOpportunityV2InlineFragment";
};
export type coursesWithVisibilityConfigsTable_LearnOpportunityV2InlineFragment$key = {
  readonly " $data"?: coursesWithVisibilityConfigsTable_LearnOpportunityV2InlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"coursesWithVisibilityConfigsTable_LearnOpportunityV2InlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "coursesWithVisibilityConfigsTable_LearnOpportunityV2InlineFragment"
};

(node as any).hash = "c8c89cee4251c8264c8cc8daaf7652ff";

export default node;
