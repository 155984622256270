import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query selectUserInAccountGroupsField_Query($id: ID!) {
		Admin {
			Auth {
				GetUserInAccountGroups(id: $id) {
					id
					name
					permissions
				}
			}
		}
	}
`;

export const VISIBILITY_SCHEDULE_USERS_QUERY = graphql`
	query selectUserInAccountGroupsField_VisibilityScheduleUserInAccountGroupsQuery(
		$accountId: ID!
	) {
		Admin {
			VisibilitySchedule {
				SearchUserInAccountGroupsForVisibilitySchedule(filterByAccountId: $accountId) {
					id
					name
				}
			}
		}
	}
`;
