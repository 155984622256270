import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query selectUserFieldDialog_Query($userId: ID!, $skip: Boolean!) {
		node(id: $userId) @skip(if: $skip) {
			... on User {
				name
				email
			}
		}
	}
`;

export const VISIBILITY_SCHEDULE_USER_QUERY = graphql`
	query selectUserFieldDialog_VisibilityScheduleUserQuery($userId: ID!) {
		Admin {
			VisibilitySchedule {
				GetUserForVisibilitySchedule(id: $userId) {
					name
					email
				}
			}
		}
	}
`;
