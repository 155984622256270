import graphql from "babel-plugin-relay/macro";

export const ACCOUNTS_QUERY = graphql`
	query visibilityScheduleForm_AccountsQuery {
		Admin {
			VisibilitySchedule {
				GetAccountsForVisibilitySchedule {
					...selectAccountField_AccountOptionsFragment
				}
			}
		}
	}
`;
