import { graphql } from "babel-plugin-relay/macro";

export const TREE_NODE_FRAGMENT = graphql`
	fragment visibilityTreeConfigEditor_TreeNodeFragment on TreeNode {
		id
		structureDefinition {
			... on RootStructureDefinition {
				visibilityConfigs {
					id
					configType
					... on NotAfterDateTimeVisibilityTreeConfig {
						dateTime
					}
					... on AfterDateTimeVisibilityTreeConfig {
						dateTime
					}
					...NotAfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment
					...AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment
				}
			}
		}
		...NotAfterDateTimeVisibilityConfigForm_TreeNodeFragment
		...AfterDateTimeVisibilityConfigForm_TreeNodeFragment
	}
`;

export const ADD_HIDE_VISIBILITY_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation visibilityTreeConfigEditor_AddHideVisibilityConfigMutation(
		$input: AddHideVisibilityTreeConfigInput!
	) {
		Admin {
			Tree {
				addHideVisibilityTreeConfig(input: $input) {
					rootNode {
						...visibilityTreeConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

export const ADD_ONLY_ADMINS_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation visibilityTreeConfigEditor_AddOnlyAdminsVisibilityConfigMutation(
		$input: AddOnlyAdminsVisibilityTreeConfigInput!
	) {
		Admin {
			Tree {
				addOnlyAdminsVisibilityTreeConfig(input: $input) {
					rootNode {
						...visibilityTreeConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

export const ADD_ONLY_EDITORS_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation visibilityTreeConfigEditor_AddOnlyEditorsVisibilityConfigMutation(
		$input: AddOnlyEditorsVisibilityTreeConfigInput!
	) {
		Admin {
			Tree {
				addOnlyEditorsVisibilityTreeConfig(input: $input) {
					rootNode {
						...visibilityTreeConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

export const ADD_ONLY_BUSINESS_ACCOUNT_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation visibilityTreeConfigEditor_AddOnlyBusinessAccountVisibilityConfigMutation(
		$input: AddOnlyBusinessAccountVisibilityTreeConfigInput!
	) {
		Admin {
			Tree {
				addOnlyBusinessAccountVisibilityTreeConfig(input: $input) {
					rootNode {
						...visibilityTreeConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

export const ADD_NOT_AFTER_DATE_TIME_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation visibilityTreeConfigEditor_AddNotAfterDateTimeVisibilityConfigMutation(
		$input: AddNotAfterDateTimeVisibilityTreeConfigInput!
	) {
		Admin {
			Tree {
				addNotAfterDateTimeVisibilityTreeConfig(input: $input) {
					rootNode {
						...visibilityTreeConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

export const ADD_AFTER_DATE_TIME_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation visibilityTreeConfigEditor_AddAfterDateTimeVisibilityConfigMutation(
		$input: AddAfterDateTimeVisibilityTreeConfigInput!
	) {
		Admin {
			Tree {
				addAfterDateTimeVisibilityTreeConfig(input: $input) {
					rootNode {
						...visibilityTreeConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

export const ADD_ONLY_IF_TREE_STATE_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation visibilityTreeConfigEditor_AddOnlyIfTreeStateVisibilityConfigMutation(
		$input: AddOnlyIfTreeStateVisibilityTreeConfigInput!
	) {
		Admin {
			Tree {
				AddOnlyIfTreeStateVisibilityTreeConfig(input: $input) {
					rootNode {
						...visibilityTreeConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

export const REMOVE_VISIBILITY_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation visibilityTreeConfigEditor_RemoveVisibilityConfigMutation(
		$input: RemoveVisibilityTreeConfigInput!
	) {
		Admin {
			Tree {
				removeVisibilityTreeConfig(input: $input) {
					rootNode {
						...visibilityTreeConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

export const ADD_ONLY_AZAV_ACCOUNT_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation visibilityTreeConfigEditor_AddOnlyAZAVAccountVisibilityConfigMutation(
		$input: AddOnlyAZAVAccountVisibilityTreeConfigInput!
	) {
		Admin {
			Tree {
				addOnlyAZAVAccountVisibilityTreeConfig(input: $input) {
					rootNode {
						...visibilityTreeConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

export const ADD_ONLY_WITH_LICENSE_VISIBILITY_TREE_CONFIG_MUTATION = graphql`
	mutation visibilityTreeConfigEditor_AddOnlyWithLicenseVisibilityTreeConfigMutation(
		$input: AddOnlyWithLicenseVisibilityTreeConfigInput!
	) {
		Admin {
			Tree {
				addOnlyWithLicenseVisibilityTreeConfig(input: $input) {
					rootNode {
						...visibilityTreeConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;
