import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query coursesWithVisibilityConfigs_Query(
		$first: Int
		$after: String
		$before: String
		$last: Int
		$visibilityTreeConfigKinds: [VisibilityTreeConfigType!]!
	) {
		...coursesWithVisibilityConfigsTable_QueryFragment
			@arguments(
				first: $first
				after: $after
				before: $before
				last: $last
				visibilityTreeConfigKinds: $visibilityTreeConfigKinds
			)
	}
`;
