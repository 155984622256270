import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query visibilitySchedules_Query(
		$first: Int
		$after: String
		$before: String
		$last: Int
		$accountId: ID
		$rootId: ID!
		$dataKinds: [VisibilitySchedule_VisibilityScheduleDataKind!]!
	) {
		...visibilitySchedulesTable_QueryFragment
			@arguments(
				first: $first
				after: $after
				before: $before
				last: $last
				accountId: $accountId
				rootId: $rootId
				dataKinds: $dataKinds
			)
	}
`;

export const LEARN_OPPORTUNITYV2_QUERY = graphql`
	query visibilitySchedulesScreen_LearnOpportunityV2Query($rootId: ID!) {
		Admin {
			VisibilitySchedule {
				GetRootNodeForVisibilitySchedule(rootId: $rootId) {
					structureDefinition {
						title
					}
				}
			}
		}
	}
`;
