import { Dialog, EpicIcons } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { useRelayEnvironment } from "react-relay";

import { fetchQuery } from "relay-runtime";
import * as Yup from "yup";
import { Button } from "@components/button";
import { inputWrapperClass } from "@features/users/select-user-field-dialog/select-user-field-dialog.styles";
import {
	selectUserFieldDialog_Query,
	selectUserFieldDialog_Query$data,
} from "@relay/selectUserFieldDialog_Query.graphql";

import {
	selectUserFieldDialog_VisibilityScheduleUserQuery,
	selectUserFieldDialog_VisibilityScheduleUserQuery$data,
} from "@relay/selectUserFieldDialog_VisibilityScheduleUserQuery.graphql";
import { QUERY, VISIBILITY_SCHEDULE_USER_QUERY } from "./select-user-field-dialog.graphql";
import {
	type SelectUserFieldDialogState,
	type SelectUserFieldDialogProps,
} from "./select-user-field-dialog.interface";

import { ValidatedField } from "../../../components/ValidatedField";
import { SelectUserField } from "../select-user-field";

export const SelectUserFieldDialog = ({
	updateField,
	fieldValue: selectedUserId,
	selectEditors,
	selectVisibilityScheduleUsers,
	disabled,
	onChange,
	accountId,
}: SelectUserFieldDialogProps) => {
	const [user, setUser] = useState<
		| Writeable<selectUserFieldDialog_Query$data["node"]>
		| Writeable<
				selectUserFieldDialog_VisibilityScheduleUserQuery$data["Admin"]["VisibilitySchedule"]["GetUserForVisibilitySchedule"]
		  >
		| undefined
	>();
	const [isModalOpen, setModalOpen] = useState<boolean>(false);

	const environment = useRelayEnvironment();

	const formik = useFormik<SelectUserFieldDialogState>({
		initialValues: {
			userId: selectedUserId,
		},
		validationSchema: Yup.object().shape({
			userId: Yup.string().required("Ein Benutzer wird benötigt."),
		}),
		onSubmit: (values) => {
			updateField(values.userId);
			fetchUserData(values.userId);
			setModalOpen(false);
			onChange?.();
		},
	});

	const fetchUserData = async (userId: string | undefined) => {
		if (!userId) return;

		if (selectVisibilityScheduleUsers) {
			const result = await fetchQuery<selectUserFieldDialog_VisibilityScheduleUserQuery>(
				environment,
				VISIBILITY_SCHEDULE_USER_QUERY,
				{
					userId,
				},
			).toPromise();
			setUser(result?.Admin.VisibilitySchedule.GetUserForVisibilitySchedule);
		} else {
			const result = await fetchQuery<selectUserFieldDialog_Query>(environment, QUERY, {
				userId,
				skip: !userId,
			}).toPromise();
			setUser(result?.node);
		}
	};

	const handleDialogOnHide = () => {
		setModalOpen(false);
	};

	const handleSelectUserOnClick = () => {
		setModalOpen(true);
	};

	const handleUnselectUserOnClick = () => {
		updateField(undefined);
	};

	const name =
		user?.name && user?.email ? `${user?.name} - ${user?.email}` : "Kein Benutzer ausgewählt";

	return (
		<>
			<div className={inputWrapperClass}>
				<InputText
					onClick={handleSelectUserOnClick}
					className="mr-2 flex-grow-1 w-auto"
					disabled={true}
					value={name}
				/>
				<Button
					disabled={selectedUserId === undefined || disabled}
					icon={EpicIcons.TIMES}
					onClick={handleUnselectUserOnClick}
				/>
				<Button
					label={"Benutzer auswählen"}
					disabled={disabled}
					onClick={handleSelectUserOnClick}
				/>
			</div>
			<Dialog
				headerNode="Benutzer auswählen"
				onHide={handleDialogOnHide}
				visible={isModalOpen}
			>
				<form onSubmit={formik.handleSubmit}>
					<ValidatedField<SelectUserFieldDialogState, string>
						className="mb-4"
						name={"userId"}
						label={"Wähle einen Benutzer aus"}
						formikConfig={{
							...formik,
							errors: { ...formik.errors, userId: undefined },
						}}
						component={(config) => {
							return (
								<SelectUserField
									{...config}
									selectEditors={selectEditors}
									selectVisibilityScheduleUsers={selectVisibilityScheduleUsers}
									accountId={accountId}
								/>
							);
						}}
					/>
					<Button
						disabled={!formik.isValid}
						label={"Auswählen"}
						icon={EpicIcons.CHECK}
						onClick={formik.submitForm}
					/>
				</form>
			</Dialog>
		</>
	);
};
