import { FormDialogButton, type PathParams } from "@thekeytechnology/epic-ui";
import { Toast } from "primereact/toast";
import React, { useContext, useRef } from "react";
import { useSelector } from "react-redux";
import { useMutation } from "react-relay";
import { useParams } from "react-router-dom";
import { match } from "ts-pattern";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { createVisibilityScheduleButton_CreateSingleUserVisibilityScheduleMutation } from "@relay/createVisibilityScheduleButton_CreateSingleUserVisibilityScheduleMutation.graphql";
import { createVisibilityScheduleButton_CreateUserInAccountGroupVisibilityScheduleMutation } from "@relay/createVisibilityScheduleButton_CreateUserInAccountGroupVisibilityScheduleMutation.graphql";
import { CoursesWithVisibilitySchedulesPaths } from "@screens/courses-with-visibility-configs";
import { VisibilityScheduleForm } from "@screens/visibility-schedules/parts/visibility-schedule-form/visibility-schedule-form.component";
import { VisibilityScheduleFormState } from "@screens/visibility-schedules/parts/visibility-schedule-form/visibility-schedule-form.types";
import { VisibilitySchedulesScreenContext } from "@screens/visibility-schedules/visibility-schedules.context";
import { selectCurrentAccountId } from "@store/slices/AuthSlice";
import {
	CREATE_SINGLE_USER_VISIBILITY_SCHEDULE_MUTATION,
	CREATE_USER_IN_ACCOUNT_GROUP_VISIBILITY_SCHEDULE_MUTATION,
} from "./create-visibility-schedule-button.graphql";

export const CreateVisibilityScheduleButton = () => {
	const toast = useRef<Toast>(null);
	const { rootId } = useParams<PathParams<typeof CoursesWithVisibilitySchedulesPaths>>();
	const currentAccountId = useSelector(selectCurrentAccountId) ?? "";

	const isRootAccount = useHasPermissions(["AccountPermission_System_Root"]);

	const { connectionId } = useContext(VisibilitySchedulesScreenContext);

	const [createSingleUserVisibilitySchedule, isCreatingSingleUserVisibilitySchedule] =
		useMutation<createVisibilityScheduleButton_CreateSingleUserVisibilityScheduleMutation>(
			CREATE_SINGLE_USER_VISIBILITY_SCHEDULE_MUTATION,
		);

	const [
		createUserInAccountGroupVisibilitySchedule,
		isCreatingUserInAccountGroupVisibilitySchedule,
	] =
		useMutation<createVisibilityScheduleButton_CreateUserInAccountGroupVisibilityScheduleMutation>(
			CREATE_USER_IN_ACCOUNT_GROUP_VISIBILITY_SCHEDULE_MUTATION,
		);

	if (!rootId) return;

	const onCompleted = (onHide: () => void) => {
		onHide();
		toast.current?.show({
			severity: "success",
			life: 3000,
			summary: "Kurs-Verfügbarkeit gespeichert",
			detail: "Die Kurs-Verfügbarkeit wurde erfolgreich erstellt",
		});
	};

	const onError = () => {
		toast.current?.show({
			severity: "error",
			life: 3000,
			summary: "Kurs-Verfügbarkeit gespeichert",
			detail: "Die Kurs-Verfügbarkeit wurde nicht erstellt",
		});
	};

	const getAccountId = (accountId?: string) => {
		return isRootAccount ? accountId! : currentAccountId;
	};

	const handleCreateSingleUserVisibilitySchedule = (
		values: VisibilityScheduleFormState,
		onHide: () => void,
	) => {
		if (!values.userId) return;
		createSingleUserVisibilitySchedule({
			variables: {
				input: {
					visibleFrom: values.visibleFrom,
					visibleUntil: values.visibleUntil,
					rootId: rootId,
					data: {
						userIds: values.userId,
					},
					accountId: getAccountId(values.account?.id),
				},
				connections: [connectionId],
			},
			onCompleted: () => onCompleted(onHide),
			onError,
		});
	};
	const handleCreateUserInAccountGroupVisibilitySchedule = (
		values: VisibilityScheduleFormState,
		onHide: () => void,
	) => {
		if (!values.userInAccountGroupId) return;
		createUserInAccountGroupVisibilitySchedule({
			variables: {
				input: {
					visibleFrom: values.visibleFrom,
					visibleUntil: values.visibleUntil,
					rootId: rootId,
					data: {
						userInAccountGroupIds: values.userInAccountGroupId,
					},
					accountId: getAccountId(values.account?.id),
				},
				connections: [connectionId],
			},
			onCompleted: () => onCompleted(onHide),
			onError,
		});
	};

	const handleVisibilityScheduleFormOnSubmit = (
		onHide: () => void,
		values: VisibilityScheduleFormState,
	) => {
		match(values)
			.with({ dataKind: "SingleUserData" }, () =>
				handleCreateSingleUserVisibilitySchedule(values, onHide),
			)
			.with({ dataKind: "UserInAccountGroupData" }, () =>
				handleCreateUserInAccountGroupVisibilitySchedule(values, onHide),
			);
	};

	return (
		<>
			<Toast ref={toast}></Toast>
			<FormDialogButton<VisibilityScheduleFormState>
				buttonLabel="Kurs-Verfügbarkeit erstellen"
				dialogTitle="Daten zum Erstellen der Kurs-Verfügbarkeit eingeben"
				disabled={
					isCreatingSingleUserVisibilitySchedule ||
					isCreatingUserInAccountGroupVisibilitySchedule
				}
			>
				{({ formikRef, onHide }) => {
					return (
						<VisibilityScheduleForm
							ref={formikRef}
							onSubmit={(values) => {
								handleVisibilityScheduleFormOnSubmit(onHide, values);
							}}
						/>
					);
				}}
			</FormDialogButton>
		</>
	);
};
